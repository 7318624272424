<template>
  <header-page></header-page>
  <main>
    <section class="companies">
      <div class="companies__container">
        <div class="companies__content">
          <SideBar></SideBar>
          <div class="right__main__content">
            <div class="left__content">
              <div class="title__wrapper">
                <div class="title">Messages</div>
                <div v-if="totalUnreadMessages !== 0" class="count__messages">
                  ({{ totalUnreadMessages }})
                </div>
              </div>
              <div class="button__wrapper">
                <InputFilterUser
                  :class="'w-100'"
                  :placeholder_title="'Search'"
                  @value="getSearchData"
                  :data_name="'name'"
                  :sort="arr_users"
                  @search="getSearchEvent"
                >
                </InputFilterUser>
              </div>
              <ChatList
                :chatList="chatsData"
                :selectChat="chatId"
                @changeChat="chatChange"
              ></ChatList>
            </div>
            <div class="right__content" v-if="chatId">
              <div class="top__bar">
                <div class="top_bar_wrapper">
                  <div class="flex">
                    <div
                      class="chat__close"
                      @click="
                        chatId = null;
                        messages = [];
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                      >
                        <path
                          d="M0.707107 5.70711C0.316582 5.31658 0.316582 4.68342 0.707107 4.29289L4.29289 0.707106C4.92286 0.0771415 6 0.523308 6 1.41421L6 8.58579C6 9.47669 4.92286 9.92286 4.29289 9.29289L0.707107 5.70711Z"
                          fill="#292929"
                          fill-opacity="0.8"
                        />
                      </svg>
                    </div>
                    <div class="user__name">
                      ID {{ chatsData[chatId].id
                      }}{{
                        chatsData[chatId].user.name
                          ? "(" +
                            chatsData[chatId].user.name +
                            "" +
                            (chatsData[chatId].user.last_name
                              ? " " + chatsData[chatId].user.last_name
                              : "") +
                            ")"
                          : false
                      }}
                    </div>
                  </div>
                  <div
                    v-if="chatsData[chatId].user.online === 2"
                    class="status__user online"
                  >
                    Online
                  </div>
                  <div v-else class="status__user offline">Offline</div>
                </div>
              </div>
              <massages
                :messageSend="data_info.message_send"
                :messages="messages"
                :otherUser="chatsData[chatId]"
                @loadMore="loadMoreMessages"
                :next="data_info.next"
                :loading="data_info.loading"
                @messageRead="markAsRead"
              ></massages>
              <message-send
                @send="handleFileUpload"
                @sendText="sendText"
              ></message-send>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SideBar from "@/components/Customer/SideBar.vue";
import ChatList from "@/components/Customer/MessagesPage/ChatList.vue";
import axios from "axios";
import InputFilterUser from "@/components/Customer/MessagesPage/InputFilterUser.vue";
export default {
  components: { InputFilterUser, SideBar, ChatList },
  data() {
    return {
      filters: {
        status_id: 1,
      },
      socket: "",
      arr_event: [],
      arr_users: [],
      chatsData: [],
      chatId: null,
      new_message: "",
      messages: [],
      data_info: {
        page: 0,
        next: true,
        loading: false,
        message_send: false,
      },
      real_filters: {
        status_id: 1,
      },
      arr_status: [
        { id: 1, status_name: "Customers" },
        { id: 2, status_name: "Amuse" },
      ],
    };
  },
  methods: {
    getSearchData(e) {
      this.getChats(e);
    },
    getSearchEvent(e) {
      let fData = new FormData();
      fData.append("name", e);
      fData.append("status", this.real_filters.status_id);
      axios
        .post("/customer/messages/get/search-user", fData)
        .then((resp) => {
          this.arr_users = resp.data.data;
        })
        .catch(() => {})
        .finally(() => {});
    },
    getChats(e) {
      axios
        .post("/customer/messages/get/chats", {
          status: this.real_filters.status_id,
          chat_id: e,
        })
        .then((response) => {
          this.chatsData = response.data.data;
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has("chat_id")) {
            const url = new URL(window.location.href);
            this.chatChange(urlParams.get("chat_id"));
            url.searchParams.delete("chat_id");
            window.history.pushState({}, "", url.toString());
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadMoreMessages() {
      this.data_info.loading = true;
      try {
        const response = await axios.get(
          `/customer/messages/get/chat-messages`,
          {
            params: {
              chat_id: this.chatId,
              page: this.data_info.page,
            },
          }
        );
        let data = response.data.data;
        data = data.reverse();
        this.messages = [...data, ...this.messages];
        this.data_info.next = response.data.next;
        this.data_info.page++;
      } catch (error) {
        console.error("Error loading messages:", error);
      } finally {
        this.data_info.loading = false;
      }
    },
    async getMessages(id) {
      try {
        const resp = await axios
          .get("/customer/messages/get/chat-messages", {
            params: {
              chat_id: id,
              page: this.data_info.page,
            },
          })
          .then((response) => {
            this.messages = response.data.data;
            this.messages = this.messages.reverse();
            this.data_info.next = response.data.next;
            this.data_info.page++;
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error("Error loading messages:", error);
      }
    },
    sendText(value) {
      axios
        .post("/customer/chat/" + this.chatId + "/message", {
          text: value,
          type: "text",
        })
        .then((response) => {
          this.chatsData[response.data.data.chat_id].message =
            response.data.data;
          this.socket.send(JSON.stringify(response.data.data));
        });
    },
    handleFileUpload(value) {
      if (value) {
        const fileType = value.type;
        if (fileType.includes("image")) {
          this.uploadImageToDatabase(value);
        } else if (fileType.includes("video")) {
          this.uploadVideoToDatabase(value);
        } else {
          this.uploadFileToDatabase(value);
        }
      }
    },
    uploadImageToDatabase(file) {
      axios
        .post(
          "/customer/chat/" + this.chatId + "/message",
          {
            text: file,
            type: "image",
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.chatsData[response.data.data.chat_id].message =
            response.data.data;
          this.socket.send(JSON.stringify(response.data.data));
        });
    },
    uploadVideoToDatabase(file) {
      axios
        .post(
          "/customer/chat/" + this.chatId + "/message",
          {
            text: file,
            type: "video",
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.chatsData[response.data.data.chat_id].message =
            response.data.data;
          this.socket.send(JSON.stringify(response.data.data));
        });
    },
    uploadFileToDatabase(file) {
      axios
        .post(
          "/customer/chat/" + this.chatId + "/message",
          {
            text: file,
            type: "file",
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.chatsData[response.data.data.chat_id].message =
            response.data.data;
          this.socket.send(JSON.stringify(response.data.data));
        });
    },

    chatChange(id) {
      this.data_info.page = 0;
      this.data_info.next = true;
      this.chatId = id;
      if (this.socket) {
        this.socket.close();
      }

      this.socket = new WebSocket("wss://amusemeow.com/ws/");
      // this.socket = new WebSocket('wss://amuse.qwy-dev.net/ws');
      this.socket.onopen = () => {
        this.socket.send(JSON.stringify({ command: "join", id: this.chatId }));
      };
      this.socket.onmessage = (event) => {
        console.log("Raw event data:", event);
        let data;
        try {
          data = JSON.parse(event.data);
        } catch (e) {
          console.error("Error parsing JSON:", e);
          return;
        }

        console.log("Parsed data:", data);

        if (data.event === "MessageRead") {
          const index = this.messages.findIndex(
            (message) => message.id === data.message
          );
          if (index !== -1) {
            this.messages[index].is_read = 1;
          } else {
            console.error("Сообщение с указанным id не найдено.");
          }
        }

        if (data.event === "Message") {
          let message;
          try {
            message = JSON.parse(data.message);
            this.chatsData[message.chat_id].message = message;
            if (message.chat_id != this.chatId) {
              this.chatsData[message.chat_id].unread =
                this.chatsData[message.chat_id].unread + 1;
            }
            this.data_info.message_send = true;
            this.messages.push(message);
            setTimeout(() => (this.data_info.message_send = false), 3000);
          } catch (e) {
            console.error("Error parsing message JSON:", e);
            return;
          }

          console.log("Parsed message:", message);
        }
      };
      this.socket.onclose = (event) => {};
      this.getMessages(this.chatId);
    },
    markAsRead(messageId) {
      axios
        .post(`/customer/mark-as-read/` + this.chatId + `/` + messageId)
        .then((response) => {
          if (this.chatsData[this.chatId].unread > 0) {
            this.chatsData[this.chatId].unread--;
          } else {
            this.chatsData[this.chatId].unread = 0;
          }
          this.markMessageAsRead(messageId);
        })
        .catch((error) => {
          console.error("Error marking as read:", error);
        });
    },
    markMessageAsRead(messageId) {
      this.socket.send(
        JSON.stringify({ command: "read", messageId: messageId })
      );
    },
  },
  mounted() {
    this.getSearchEvent('');
    this.getChats();
    this.intervalId = setInterval(() => {
      this.getChats();
    }, 30000);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  computed: {
    totalUnreadMessages() {
      const unreadCounts = Object.values(this.chatsData).map(
        (chat) => chat.unread
      );
      return unreadCounts.reduce((total, count) => total + count, 0);
    },
  },
};
</script>

<style scoped>
.btn {
  gap: 12px;
}

button.white {
  width: 160px;
}

.top_bar_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.button__wrapper {
  position: relative;
}

.input__filter__wrapper.open {
  position: unset;
  left: unset;
  top: unset;
  width: 100%;
  z-index: 10;
}

.picker-option {
  max-width: unset;
}

.picker-option button {
  width: 100%;
}

.chat__close {
  display: none;
}

.input__filter__wrapper {
  width: 100%;
  justify-content: unset;
}

.button_filter {
  width: 100%;
}

.picker-option button {
  width: 100%;
}

.right__main__content {
  display: flex;
  overflow: hidden;
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #e5e5ea);
  background: #f5f5f5;

  /* 2 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
    0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  flex: 1;
  max-height: calc(100vh - 164px);
}

.left__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  width: 35%;
  padding: 16px 20px;
  border-right: 1.5px solid var(--gray-stroke, #e5e5ea);
}

.right__content {
  width: 65%;
  display: flex;
  flex-direction: column;
}

.title__wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.title__wrapper .title {
  color: #292929;
  font-family: Standerd;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.144px;
}

.title__wrapper .count__messages {
  color: var(--red-200, #ea2313);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
}

.top__bar {
  align-items: center;
  padding: 15px;
  min-height: 88px;
  display: flex;
  justify-content: space-between;
  background: white;
  border-bottom: 1.5px solid var(--gray-stroke, #e5e5ea);
}

.top__bar .user__name {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.096px;
}

.top__bar .status__user {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px;
  height: 28px;
  width: max-content;
}

.status__user.online {
  background: var(--green-100, #aff4c6);
  color: var(--green-400, #39766a);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;

  line-height: normal;
  letter-spacing: 0.084px;
}

.status__user.offline {
  background: var(--red-0, #ffebeb);
  color: var(--green-400, #ea2313);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
}

@media screen and (max-width: 1024px) {
  .btn {
    display: none;
  }

  .top_bar_wrapper {
    flex-direction: row;
    width: 100%;
  }

  .left__content {
    width: 100%;
    height: 100%;
    padding: 0;
    border-right: unset;
  }

  .right__main__content {
    display: flex;
    overflow: hidden;
    border-radius: unset;
    border: unset;
    background: #f5f5f5;
    box-shadow: unset;
    flex: 1;
    max-height: calc(100vh - 164px);
    position: relative;
  }

  .right__content {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 8;
    background: #ffffff;
    padding-top: 100px;
    padding-bottom: 86px;
  }

  .top__bar {
    min-height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: white;
    border-bottom: 1.5px solid var(--gray-stroke, #e5e5ea);
    align-items: center;
  }

  .top__bar .user__name {
    color: var(--black-400, #292929);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.096px;
  }

  .flex {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .chat__close {
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    display: flex;
    border-radius: 6px;
    border: 1px solid #d1d1d6;
    background: #fff;
  }
}

@media screen and (max-width: 768px) {
  .right__content {
    padding-top: 72px;
  }
}
</style>
