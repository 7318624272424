<template>
    <header-page></header-page>
    <main>
        <section class="companies">
            <div class="companies__container">
                <div class="companies__content">
                    <SideBar></SideBar>
                    <div class="right__main__content">
                        <div class="contro__module">
                            <div class="left">
                                <SelectEvents class="mobile__none" @value="getSearchData"
                                    :placeholder_title="'Select event'" :data_name="'event_name'" :sort="arr_event"
                                    @search="getSearchEvent"></SelectEvents>
                                <div class="picker-option">
                                    <template v-for="status in arr_status">
                                        <button :class="[status.status_name, { 'active': selectedStatus == status.id }]"
                                            @click="changeStatus(status.id)" type="button">
                                            {{ status.status_name }}
                                        </button>
                                        <div class="arrow__top"></div>
                                    </template>
                                </div>
                            </div>
                            <div class="right mobile__none">
                                <div class="title">{{ getDayOfMonth() }}</div>
                                <div class="calendar__button">
                                    <button @click="nextStep = false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none">
                                            <g clip-path="url(#clip0_4857_55074)">
                                                <path
                                                    d="M9.70696 12.707C9.31643 12.3165 9.31643 11.6833 9.70696 11.2928L13.2927 7.70701C13.9227 7.07704 14.9998 7.52321 14.9998 8.41411L14.9998 15.5857C14.9998 16.4766 13.9227 16.9228 13.2927 16.2928L9.70696 12.707Z"
                                                    fill="#292929" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4857_55074">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg></button>
                                    <button @click="nextStep = true">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none">
                                            <g clip-path="url(#clip0_4857_55114)">
                                                <path
                                                    d="M14.7071 10.8809C15.0976 11.2714 15.0976 11.9046 14.7071 12.2951L11.1213 15.8809C10.4914 16.5108 9.41421 16.0647 9.41421 15.1738L9.41421 8.0022C9.41421 7.1113 10.4914 6.66513 11.1213 7.2951L14.7071 10.8809Z"
                                                    fill="#292929" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4857_55114">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="calendar__wrapper">
                            <DayCalendar v-if="selectedStatus == 0" :current_date="currentDate"
                                @currentDate="currentDate = $event" @step="nextStep = $event" :step="nextStep"
                                :eventsData="eventsData"
                                @add_customers="details_data = $event; dialogs.addCustomersDialog = true;"
                                @add_slots="details_data.id = $event; dialogs.addSlotsDialog = true;"
                                @choise_date="getEventDateTime" @delete="getEventDelete">
                                <template v-slot:select_event>
                                    <SelectEvents class="mobile mg-16" @value="getSearchData"
                                        :placeholder_title="'Select event'" :data_name="'event_name'" :sort="arr_event"
                                        @search="getSearchEvent"></SelectEvents>
                                </template>
                            </DayCalendar>
                            <MonthCalendar v-if="selectedStatus == 1" @status_change="selectedStatus = $event"
                                :current_date="currentDate" @currentDate="currentDate = $event; getMonth();"
                                :events_data="events_data" @step="nextStep = $event" :step="nextStep">
                                <template v-slot:select_event>
                                    <SelectEvents class="mobile" @value="getSearchData" :placeholder_title="'Select event'"
                                        :data_name="'event_name'" :sort="arr_event" @search="getSearchEvent"></SelectEvents>
                                </template>
                            </MonthCalendar>
                        </div>
                    </div>
                    <modal-dialog v-model:show="dialogs.addCustomersDialog" :modal="true">
                        <div>
                            <div class="title">Add customers</div>
                            <div class="text">All added offline customers are not charged</div>
                        </div>
                        <NumberPicker v-model="details_data.customers"></NumberPicker>
                        <div class="btn">
                            <Button class="white"
                                @click="this.dialogs.addCustomersDialog = false; details_data = {};">Cancel</Button>
                            <Button class="green" @click="addCustomers">Add</Button>
                        </div>
                        <button class="exit-dialog" @click="this.dialogs.addCustomersDialog = false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                                    fill="#292929" />
                            </svg>
                        </button>
                    </modal-dialog>
                    <modal-dialog v-model:show="dialogs.addSlotsDialog" :modal="true">
                        <div>
                            <div class="title">Add tickets</div>
                        </div>
                        <NumberPicker v-model="details_data.slots"></NumberPicker>
                        <div class="btn">
                            <Button class="white"
                                @click="this.dialogs.addSlotsDialog = false; details_data = {};">Cancel</Button>
                            <Button class="green" @click="addSlots">Add</Button>
                        </div>
                        <button class="exit-dialog" @click="this.dialogs.addSlotsDialog = false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                                    fill="#292929" />
                            </svg>
                        </button>
                    </modal-dialog>
                    <ChangeDatePickerDialog @show="dialogs.changeDateCalendar = $event" :modal="true"
                        :show="dialogs.changeDateCalendar" :value_start="changeDataEvent.start_date"
                        :value_end="changeDataEvent.end_date" @add="dateConfirm">
                    </ChangeDatePickerDialog>
                    <modal-dialog v-model:show="dialogs.changeTimeCalendar" :modal="true">
                        <div>
                            <div class="title">Choose time</div>
                        </div>
                        <Select @update:modelValue="handleUpdate" :selected="selected_time"
                            :placeholder_title="'Order time'" :data_name="'title'" :sort="changeTimeEvent"></Select>
                        <div class="btn">
                            <Button class="white" @click="this.dialogs.changeTimeCalendar = false;">Cancel</Button>
                            <Button class="green" @click="updateDataValue">Confirm</Button>
                        </div>
                    </modal-dialog>
                    <modal-dialog v-model:show="dialogs.DeleteItemDialog" :modal="true">
                        <div>
                            <div class="title">Removing an event</div>
                            <div class="text">Specify the reason why you want to delete the surfing event on
                                {{ details_data.day_execution }}</div>
                        </div>
                        <textarea class="textarea" v-model="details_data.reason_delete" type="text" name="message"
                            data-valid="message" required=""></textarea>
                        <div class="btn">
                            <Button class="white" @click="this.dialogs.DeleteItemDialog = false">Cancel</Button>
                            <Button class="red" @click="deleteEvent">Delete</Button>
                        </div>
                        <button class="exit-dialog" @click="this.dialogs.DeleteItemDialog = false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                                    fill="#292929" />
                            </svg>
                        </button>
                    </modal-dialog>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import axios from "axios";
import SideBar from "@/components/Busines/SideBar.vue";
import SelectEvents from "@/components/Busines/Calendar/SelectEvents.vue";
import DayCalendar from "@/components/Busines/Calendar/DayCalendar.vue";
import MonthCalendar from "@/components/Busines/Calendar/MonthCalendar.vue";
import NumberPicker from "@/components/Busines/NewEvent/NumberPicker.vue";
import ChangeDatePickerDialog from "@/components/Busines/Calendar/ChangeDatePickerDialog.vue";
import HeaderBusines from "@/components/Busines/HeaderBusines.vue";
export default {
    components: {
        SideBar, SelectEvents, DayCalendar, MonthCalendar, NumberPicker, ChangeDatePickerDialog,HeaderBusines
    },
    data() {
        return {
            arr_status: [
                { status_name: 'Day', id: 0 },
                { status_name: 'Month', id: 1 }
            ],
            updateData: { date: null, id: null, event_id: null },
            details_data: {},
            changeDataEvent: [],
            changeTimeEvent: [],
            currentDate: new Date(),
            eventsData: [],
            selected_time: '',
            selectedStatus: 0,
            nextStep: null,
            events_data: [],
            event_id: '',
            dialogs: {
                addCustomersDialog: false,
                addSlotsDialog: false,
                changeDateCalendar: false,
                changeTimeCalendar: false,
                DeleteItemDialog: false,
            },
            param1: '',
            arr_event: [],
        }
    },
  created() {
    if (this.$route.query.date !== undefined) {
      const originalDate = new Date(this.$route.query.date);
      this.currentDate = originalDate;
    }
  },
    methods: {
        updateDataValue() {
            let fData = new FormData();
            fData.append("day_execution", this.details_data.day_execution);
            fData.append("date", this.updateData.date);
            fData.append("time", this.updateData.id);
            fData.append("event_id", this.updateData.event_id);
            fData.append("prev_event_id", this.details_data.event_id);
            axios.post("/business/calendar/change/date-time", fData).then(resp => {
                this.dialogs.changeTimeCalendar = false;
                this.getDay();
            }).catch(() => { }).finally(() => { });
        },
        handleUpdate(option) {
            this.updateData.id = option.id;
            this.updateData.event_id = option.event_id;
            this.selected_time = '';
        },
        getDayOfMonth() {
            const dayOfMonth = this.$store.state.months_names[this.currentDate.getMonth()] + ' ' + this.currentDate.getFullYear();

            return dayOfMonth;
        },
        changeStatus(e) {
            this.selectedStatus = e;
        },
        getDay() {
            let fData = new FormData();
            if (this.event_id) fData.append("event_id", this.event_id);
            fData.append("date", this.currentDate.getFullYear() + '-' + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + '-' + ('0' + (this.currentDate.getDate())).slice(-2));
            axios.post("/business/calendar/get-date", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.eventsData = resp.data.data;

                }
            }).catch(() => { }).finally(() => {
            });
        },
        getMonth() {
            console.log(123);
            let fData = new FormData();
            if (this.event_id) fData.append("event_id", this.event_id);
            fData.append("date", this.currentDate.getFullYear() + '-' + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + '-' + ('0' + (this.currentDate.getDate())).slice(-2));
            axios.post("/business/calendar/get-month", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.events_data = resp.data.data;
                }
            }).catch(() => { }).finally(() => {
            });
        },
        addCustomers({ id, day_execution }) {
            let fData = new FormData();
            fData.append("id", this.details_data.id);
            fData.append("customers", this.details_data.customers);
            fData.append("day_execution", this.details_data.day_execution);
            axios.post("/business/calendar/add-customers", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.dialogs.addCustomersDialog = false;
                    this.getDay();
                }
            }).catch(() => { }).finally(() => { });
        },
        addSlots() {
            let fData = new FormData();
            fData.append("id", this.details_data.id);
            fData.append("slots", this.details_data.slots);
            axios.post("/business/calendar/add-slots", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.dialogs.addSlotsDialog = false;
                    this.getDay();
                }
            }).catch(() => { }).finally(() => { });
        },
        getSearchData(e) {
            this.event_id = e;
            if (this.selectedStatus == 0) this.getDay();
            else if (this.selectedStatus == 1) this.getMonth();
        },
        getSearchEvent(e) {
            let fData = new FormData();
            fData.append("name", e);
            fData.append("status_id", this.selectedStatus);
            axios.post("/business/calendar/event-search", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.arr_event = resp.data.events;
                }
            }).catch(() => { }).finally(() => { });
        },
        getEventDateTime(item) {
            this.details_data = item;
            let fData = new FormData();
            fData.append("event_id", this.details_data.id);
            axios.post("/business/calendar/get/date-event", fData).then(resp => {
                this.changeDataEvent = resp.data.data;
                this.dialogs.changeDateCalendar = true;
            }).catch(() => { }).finally(() => { });
        },
        getEventDelete(item) {
            this.details_data = item;
            this.dialogs.DeleteItemDialog = true;
        },
        deleteEvent(){
            let fData = new FormData();
            fData.append("event_id", this.details_data.id);
            fData.append("day_execution", this.details_data.day_execution);
            fData.append("message", this.details_data.reason_delete);
            axios.post("/business/calendar/delete/event", fData).then(resp => {
                this.getDay();
                this.getMonth();
                this.dialogs.DeleteItemDialog = false;
            }).catch(() => { }).finally(() => { });
        },
        dateConfirm(event) {
            this.updateData.date = event;
            let fData = new FormData();
            fData.append("event_id", this.details_data.id);
            fData.append("date", this.updateData.date);
            axios.post("/business/calendar/get/time-event", fData).then(resp => {
                this.changeTimeEvent = resp.data.data;
                this.dialogs.changeTimeCalendar = true;
            }).catch(() => { }).finally(() => { });
        }
    },
    mounted() {
        this.getSearchEvent('');
        this.getDay();
        this.getMonth();
    },
    watch: {
        currentDate(newValue, oldValue) {
            if (this.selectedStatus == 0) this.getDay();
            if (this.selectedStatus == 1) this.getMonth();
        },
        'dialogs.DeleteItemDialog'(newValue, oldValue) {
            if (this.dialogs.DeleteItemDialog == false) this.details_data = {};
        },
    },
}
</script>

<style scoped>
.dialog__content .exit-dialog{
  position: absolute;
}
.picker-option {
  max-width: max-content;
}
.right__main__content {
    padding: 16px;
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
.dialog__content .text{
  max-width: 80%;
}
.contro__module {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 16px;
}

.contro__module .left {
    display: flex;
    gap: 12px;
    width: 100%;
}

.contro__module .right {
    display: flex;
    gap: 16px;
    align-items: center;
}

.contro__module .right .title {
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.108px;
    text-wrap: nowrap;
}

.contro__module .right .calendar__button {
    display: flex;
    gap: 8px;
}

.contro__module .right .calendar__button button {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-200, #CCC);
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}

@media screen and (max-width: 1024px) {
    .picker-option button {
        width: 100px;
    }
    .picker-option{
        width: max-content;
    }
}

@media screen and (max-width: 768px) {

    .mobile__none {
        display: none !important;
    }

    .contro__module .left {
        gap: 0px;
    }

    .contro__module .left {
        width: 100%;
    }

    .picker-option {
        width: 100%;
        max-width: unset;
    }

    .picker-option button {
        width: 100%;
        max-width: unset;
    }

    .right__main__content {
        padding: unset;
        border-radius: unset;
        border: unset;
        background: unset;
        box-shadow: unset;
    }
}
</style>