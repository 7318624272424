<template>
  <header-page></header-page>
  <main>
  <section class="companies">
    <div class="companies__container">
      <div class="companies__content">
        <side-bar></side-bar>
        <div class="right__main__content">
          <div class="filter__content">
            <div class="filter_content_wrapper">
            <div class="picker-option">
              <template v-for="status in arr_status">
                <button :class="[status.status_name,{'active': real_filters.status_id == status.id}]"  @click="changeStatus(status.id)" type="button">
                  {{ status.status_name }}
                </button>
                <div class="arrow__top"></div>
              </template>
            </div>
              <ActiveFilters @delete="deleteFilter" :arrFilters="arrFilters" :filters="filters"></ActiveFilters>
            </div>
            <div class="filter">
              <input-filter @value="getSearchData" :placeholder_title="'Search for company'" :data_name="'company_name'" :sort="arr_company" @search="getSearchCompany"></input-filter>
              <filter-button @click="this.dialogs.filterItemDialog =  true"></filter-button>
            </div>
          </div>
          <div>
            <post-list class="active" :posts="arr_data" :loader="data_info.loading">
            <table class="list">
              <thead>
              <tr>
                <th>Company name</th>
                <th>Event name</th>
                <th>Category</th>
                <th>Price</th>
                <th>Rate</th>
                <th>Reviews</th>
                <th>Published</th>
                <th>Status</th>
                <th></th>

              </tr>
              </thead>
              <tbody v-if="loadingСompleted">
              <tr v-for="post in this.arr_data"
                  :key="post.id">
                <td>
                  <div class="company__name">
                    <img v-if="post.company_data.image" :src='this.$store.state.base_directory + "company/"+ post.company_data.id + "/"+ post.company_data.image' alt="">
                    <div class="image_letter" :style="{ background: getBackgroundColor(post.company_data.company_name) }" v-else>{{ post.company_data.company_name.substr(0, 1) }}</div>
                    <div>{{ post.company_data.company_name }}</div>
                  </div>
                </td>
                <td >
                  <div class="table__name">Event name:</div>
                  <div class="text">{{ post.event_name }}</div></td>
                <td class="mobile__none">
                  <div class="category_wrapper">
                    <template v-for="(value, name, index) in post.category_data" >
                      <div v-if="index < 2" class="category">
                      {{ value }}
                      </div>
                      <div v-else-if="index === this.countCategory(post.category_data)" class="category grey">
                        +{{ this.countCategory(post.category_data)-1 }}
                      </div>
                    </template>
                  </div>
                </td>
                <td>
                  <div class="table__name">Price:</div>
                  <div class="text">${{ post.price }}</div>
                </td>
                <td  class="mobile__none">
                  <div class="company__rate">
                    <div>
                      <div class="company__rateNumber">{{ post.rate }}</div>
                      <svg class="company__rateStar" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path d="M10.0013 3.11533C10.0263 3.06113 10.0663 3.01522 10.1166 2.98304C10.1669 2.95086 10.2254 2.93376 10.2851 2.93376C10.3448 2.93376 10.4032 2.95086 10.4535 2.98304C10.5038 3.01522 10.5438 3.06113 10.5688 3.11533L12.5044 7.31158C12.5268 7.36009 12.5613 7.40205 12.6045 7.43345C12.6477 7.46486 12.6982 7.48465 12.7513 7.49096L17.3394 8.03471C17.3987 8.04176 17.4548 8.06566 17.5009 8.10359C17.5471 8.14151 17.5814 8.19186 17.5998 8.24868C17.6182 8.3055 17.62 8.36641 17.6048 8.42419C17.5897 8.48197 17.5583 8.53421 17.5144 8.57471L14.1226 11.7122C14.0835 11.7485 14.0543 11.7942 14.0379 11.845C14.0215 11.8958 14.0184 11.9499 14.0288 12.0022L14.9288 16.5341C14.9405 16.5927 14.9352 16.6534 14.9134 16.7091C14.8917 16.7647 14.8544 16.813 14.806 16.8481C14.7577 16.8833 14.7003 16.9038 14.6406 16.9073C14.581 16.9108 14.5215 16.8971 14.4694 16.8678L10.4382 14.611C10.3914 14.5847 10.3387 14.5709 10.2851 14.5709C10.2314 14.5709 10.1787 14.5847 10.1319 14.611L6.10068 16.8672C6.04861 16.8964 5.98927 16.9101 5.92968 16.9066C5.8701 16.9031 5.81275 16.8826 5.76443 16.8476C5.71611 16.8125 5.67883 16.7644 5.65701 16.7088C5.63519 16.6533 5.62974 16.5926 5.6413 16.5341L6.5413 12.0022C6.55184 11.9498 6.54876 11.8955 6.53234 11.8447C6.51593 11.7938 6.48673 11.748 6.44755 11.7116L3.05505 8.57408C3.01134 8.53355 2.98014 8.48136 2.96513 8.42367C2.95011 8.36599 2.95191 8.30521 2.9703 8.24851C2.9887 8.19181 3.02293 8.14155 3.06895 8.10367C3.11497 8.06579 3.17087 8.04186 3.23005 8.03471L7.8188 7.49096C7.87196 7.48476 7.92264 7.46501 7.96598 7.4336C8.00932 7.40219 8.04386 7.36017 8.0663 7.31158L10.0013 3.11533Z" fill="#FFCE47"/>
                      </svg>
                    </div>
                  </div>
                </td>
                <td class="mobile__none">
                  <div class="text reviews">{{ post.reviews }}</div>
                </td>
                <td class="mobile__none">
                  <div class="text">{{ post.published }}</div>
                </td>
                <td>
                  <div class="table__name">Status:</div>
                  <div class="company__status" :class="[ post.color ]">
                    <div>{{ post.status_name }}</div>
                  </div>
                </td>
                <td>
                  <div class="company__panelButton">
                      <button v-show="selectedStatus == 1" @click="openInNewTab(post)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <g clip-path="url(#clip0_3118_41499)">
                          <path d="M16.3333 15.8333H4.66667V4.16667H10.5V2.5H4.66667C3.74167 2.5 3 3.25 3 4.16667V15.8333C3 16.75 3.74167 17.5 4.66667 17.5H16.3333C17.25 17.5 18 16.75 18 15.8333V10H16.3333V15.8333ZM12.1667 2.5V4.16667H15.1583L6.96667 12.3583L8.14167 13.5333L16.3333 5.34167V8.33333H18V2.5H12.1667Z" fill="#292929"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_3118_41499">
                            <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <button v-show="selectedStatus == 3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clip-path="url(#clip0_5986_32891)">
                          <path d="M15.8333 15.8333H4.16667V4.16667H10V2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333V15.8333ZM11.6667 2.5V4.16667H14.6583L6.46667 12.3583L7.64167 13.5333L15.8333 5.34167V8.33333H17.5V2.5H11.6667Z" fill="#CCCCCC"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_5986_32891">
                            <rect width="20" height="20" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <router-link  v-show="selectedStatus == 2" :to="{name: 'AdminApproveEvent', params: { id:post.slug}}">
                    <button>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M2.5 14.3746V17.4996H5.625L14.8417 8.28297L11.7167 5.15798L2.5 14.3746ZM17.2583 5.86631C17.5833 5.54131 17.5833 5.01631 17.2583 4.69131L15.3083 2.74131C14.9833 2.41631 14.4583 2.41631 14.1333 2.74131L12.6083 4.26631L15.7333 7.39131L17.2583 5.86631Z" fill="#292929"/>
                      </svg>
                    </button>
                    </router-link>
                    <button @click="confirmDeleteItem(arr_data)" v-show="selectedStatus == 3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 1V2H18.5V4H17.5V17C17.5 18.1 16.6 19 15.5 19H5.5C4.4 19 3.5 18.1 3.5 17V4H2.5V2H7.5V1H13.5ZM5.5 17H15.5V4H5.5V17ZM7.5 6H9.5V15H7.5V6ZM13.5 6H11.5V15H13.5V6Z" fill="#292929"/>
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            </post-list>
            <list-preloader v-if="!loadingСompleted"></list-preloader>
            <list-flip @update="onPage" v-if="data_info.loading || arr_data.length > 0" :class="{ active: loadingСompleted }" :page="data_info.page" :currenNumber="arr_data.length" :allCount="data_info.allCount" :next="data_info.next"></list-flip>
          </div>
        </div>
        <dialog-filter v-model:show="dialogs.filterItemDialog" :modal="true">
          <div>
            <div class="title__filter">Filter</div>
            <div class="filters">
              <div class="category_filter">
                <div class="title_category">Category</div>
                <div class="category__wrapper">
                <template v-for="category in arr_category">
                  <div class="custom-control">
                    <input type="checkbox" :id="`event-${category.id}`" name="customCheck" class="custom-control-input" :value="category.id"
                           v-model="selectedCategory">
                    <label class="custom-control-label" :for="`event-${category.id}`">
                      {{category.name}}
                    </label>
                  </div>
                </template>
                </div>
              </div>
              <div class="selected">
                <div class="title">Company</div>
                <Select v-model="filters['company_id']" :placeholder_title="'Sort by company'" :data_name="'company_name'" :sort="arr_company_filters" @search="getSearchCompanyFilters"></Select>
              </div>
              <div class="selected">
                <div class="title">Event</div>
                <Select v-model="filters['id']" :placeholder_title="'Sort by event'" :data_name="'event_name'" :sort="event_filters" @search="getSearchCountry"></Select>
              </div>
              <div class="rate">
                <div class="title">Rate</div>
                <template v-for="rate in rates">
                  <div class="checkbox">
                    <input :id="rate.count" class="checkbox_input" :value="rate" type="checkbox" v-model="selectedRate" />
                    <label :for="rate.count"></label>
                    <div class="count_stars">
                      <svg v-for="n in rate.count" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M11.6586 3.73839C11.6886 3.67335 11.7367 3.61825 11.797 3.57964C11.8574 3.54103 11.9275 3.52051 11.9991 3.52051C12.0708 3.52051 12.1409 3.54103 12.2013 3.57964C12.2616 3.61825 12.3096 3.67335 12.3396 3.73839L14.6624 8.77389C14.6892 8.8321 14.7306 8.88245 14.7824 8.92014C14.8343 8.95782 14.895 8.98158 14.9586 8.98914L20.4644 9.64164C20.5356 9.65011 20.6028 9.67879 20.6582 9.7243C20.7136 9.7698 20.7547 9.83023 20.7768 9.89841C20.7989 9.9666 20.801 10.0397 20.7829 10.109C20.7647 10.1784 20.7271 10.241 20.6744 10.2896L16.6041 14.0546C16.5572 14.0982 16.5223 14.1531 16.5026 14.214C16.4829 14.2749 16.4791 14.3399 16.4916 14.4026L17.5716 19.8409C17.5857 19.9112 17.5793 19.9841 17.5532 20.0509C17.5271 20.1177 17.4823 20.1756 17.4243 20.2178C17.3663 20.2599 17.2974 20.2845 17.2258 20.2887C17.1542 20.2929 17.0829 20.2765 17.0204 20.2414L12.1829 17.5331C12.1268 17.5016 12.0635 17.485 11.9991 17.485C11.9348 17.485 11.8715 17.5016 11.8154 17.5331L6.97788 20.2406C6.9154 20.2757 6.84419 20.2921 6.77269 20.2879C6.70118 20.2837 6.63237 20.2592 6.57438 20.2171C6.5164 20.1751 6.47167 20.1173 6.44548 20.0506C6.4193 19.9839 6.41276 19.9112 6.42663 19.8409L7.50663 14.4026C7.51928 14.3397 7.51558 14.2747 7.49588 14.2136C7.47618 14.1525 7.44115 14.0975 7.39413 14.0539L3.32313 10.2889C3.27068 10.2403 3.23324 10.1776 3.21522 10.1084C3.1972 10.0392 3.19935 9.96624 3.22143 9.8982C3.24351 9.83017 3.28458 9.76986 3.33981 9.7244C3.39504 9.67894 3.46212 9.65023 3.53313 9.64164L9.03963 8.98914C9.10342 8.9817 9.16424 8.958 9.21624 8.92031C9.26825 8.88262 9.3097 8.8322 9.33663 8.77389L11.6586 3.73839Z" fill="#292929" stroke="#292929" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <svg v-for="n in 5-rate.count" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M11.6586 3.73839C11.6886 3.67335 11.7367 3.61825 11.797 3.57964C11.8574 3.54103 11.9275 3.52051 11.9991 3.52051C12.0708 3.52051 12.1409 3.54103 12.2013 3.57964C12.2616 3.61825 12.3096 3.67335 12.3396 3.73839L14.6624 8.77389C14.6892 8.8321 14.7306 8.88245 14.7824 8.92014C14.8343 8.95782 14.895 8.98158 14.9586 8.98914L20.4644 9.64164C20.5356 9.65011 20.6028 9.67879 20.6582 9.7243C20.7136 9.7698 20.7547 9.83023 20.7768 9.89841C20.7989 9.9666 20.801 10.0397 20.7829 10.109C20.7647 10.1784 20.7271 10.241 20.6744 10.2896L16.6041 14.0546C16.5572 14.0982 16.5223 14.1531 16.5026 14.214C16.4829 14.2749 16.4791 14.3399 16.4916 14.4026L17.5716 19.8409C17.5857 19.9112 17.5793 19.9841 17.5532 20.0509C17.5271 20.1177 17.4823 20.1756 17.4243 20.2178C17.3663 20.2599 17.2974 20.2845 17.2258 20.2887C17.1542 20.2929 17.0829 20.2765 17.0204 20.2414L12.1829 17.5331C12.1268 17.5016 12.0635 17.485 11.9991 17.485C11.9348 17.485 11.8715 17.5016 11.8154 17.5331L6.97788 20.2406C6.9154 20.2757 6.84419 20.2921 6.77269 20.2879C6.70118 20.2837 6.63237 20.2592 6.57438 20.2171C6.5164 20.1751 6.47167 20.1173 6.44548 20.0506C6.4193 19.9839 6.41276 19.9112 6.42663 19.8409L7.50663 14.4026C7.51928 14.3397 7.51558 14.2747 7.49588 14.2136C7.47618 14.1525 7.44115 14.0975 7.39413 14.0539L3.32313 10.2889C3.27068 10.2403 3.23324 10.1776 3.21522 10.1084C3.1972 10.0392 3.19935 9.96624 3.22143 9.8982C3.24351 9.83017 3.28458 9.76986 3.33981 9.7244C3.39504 9.67894 3.46212 9.65023 3.53313 9.64164L9.03963 8.98914C9.10342 8.9817 9.16424 8.958 9.21624 8.92031C9.26825 8.88262 9.3097 8.8322 9.33663 8.77389L11.6586 3.73839Z" stroke="#292929" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="btn">
            <Button class="white" @click="claerAll">Clear All</Button>
            <Button class="green" @click="onFilter">Show options</Button>
          </div>
          <Button class="exit-dialog" @click="this.dialogs.filterItemDialog = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
            </svg>
          </Button>
        </dialog-filter>
        <modal-dialog v-model:show="dialogs.DeleteItemDialog" :modal="true">
          <div>
            <div class="icon delete">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23 11V12H28V14H27V27C27 28.1 26.1 29 25 29H15C13.9 29 13 28.1 13 27V14H12V12H17V11H23ZM15 27H25V14H15V27ZM17 16H19V25H17V16ZM23 16H21V25H23V16Z" fill="#EA2313"/>
              </svg>
            </div>
            <div class="title">Permanently delete Event</div>
            <div class="text">Do you really want to delete Surfing event?</div>
          </div>
          <div class="btn">
            <Button class="white" @click="this.dialogs.DeleteItemDialog = false">Cancel</Button>
            <Button class="red" @click="deleteItem">Delete</Button>
          </div>
          <button class="exit-dialog" @click="this.dialogs.DeleteItemDialog = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
            </svg>
          </button>
        </modal-dialog>
      </div>
    </div>
  </section>
  </main>
</template>

<script>
import ListFlip from "@/components/Admin/ListFlip.vue";
import PostList from "@/components/Admin/AdminPanelList.vue";
import axios from "axios";
import ListPreloader from "@/components/UI/Admin/ListPreloader.vue";

export default {
  components: {ListPreloader, PostList, ListFlip},
  data() {
    return {
      loadingСompleted: false,
      rates:[
        {name:'rate', count:5},
        {name:'rate', count:4},
        {name:'rate', count:3},
        {name:'rate', count:2},
        {name:'rate', count:1}
      ],
      selectedRate:[],
      selectedCategory:[],
      arr_data: [],
      arr_status:[],
      arr_category: [],      
      arr_company_filters:[],      
      event_filters:[],
      selectedStatus: 1,
      filters: {
        'status_id': 1,
      },
      real_filters:{

      },
      arrFilters:{
        'id': "Event",
        'category_id' : 'Category',
        'company_id' : 'Company',
        'rate' : 'Rate'
      },

      arr_company:[],
      data_info:{
        loading: true,
        totalRecords: 0,
        page:0,
        next: true,
        allCount: null,
        sort:{
          field:"",
          order:1,
        },
        selectedItems: [],
        selectAll: false,
      },
      dialogs:{
        filterItemDialog: false,
        DeleteItemDialog: false,
      },
    }
  },
  methods: {
    openInNewTab(post) {
      const url = this.$router.resolve({
        name: 'EventPage',
        params: {
          country: post.country_name.toLowerCase(),
          city: post.city_name.toLowerCase(),
          id: post.slug
        }
      }).href;
      window.open(url, '_blank');
    },
    getData(){
      this.loadingСompleted = false;
      this.filters.status_id = this.selectedStatus;
      this.real_filters = this.filters;
      let fData = new FormData();
      if(this.arr_data.length<=0){
        this.data_info.page--;
        if(this.data_info.page<0) this.data_info.page = 0;
      }
      fData.append("page",this.data_info.page);
      fData.append("filters",JSON.stringify(this.real_filters));
      axios.post("/admin/events",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.arr_data = resp.data.data;
          this.data_info.allCount = resp.data.allCount;
          this.data_info.next = resp.data.next;
          this.data_info.loading = false;
        }
      }).catch(()=>{}).finally(()=>{
        this.loadingСompleted = true;
      });
    },
    onPage(e){
      this.data_info.page = e;
      this.data_info.loading = true;
      this.getData();
    },
    changeStatus(e){
      this.selectedStatus = e;
      this.data_info.loading = true;
      this.filters.status_id = this.selectedStatus;
      this.getData();
    },
    getInit(){
      axios.get('/admin/companies-init')
          .then((response) => {
            this.arr_status = response.data.status;
            // this.arr_country = response.data.country;
      })
      axios.get('/admin/events-init')
          .then((response) => {
            this.arr_category = response.data;
            // this.arr_country = response.data.country;
          })
    },
    getSearchData(e){
      if (e == '') delete this.filters['company_id'];
      else this.filters['company_id'] = e;
      this.getData();
    },
    getSearchCompany(e){
      let fData = new FormData();
      fData.append("name",e);
      fData.append("status_id",this.selectedStatus);
      axios.post("/admin/events-company-filter",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.arr_company = resp.data.company;
        }
      }).catch(()=>{}).finally(()=>{});
    },
    getSearchCompanyFilters(e){
      let fData = new FormData();
      fData.append("name",e);
      axios.post("/admin/events-company-filter",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.arr_company_filters = resp.data.company;
        }
      }).catch(()=>{}).finally(()=>{});
    },
    getSearchCountry(e){
      let fData = new FormData();
      fData.append("name",e);
      axios.post("/admin/events-event-filter",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.event_filters = resp.data.country;
        }
      }).catch(()=>{}).finally(()=>{});
    },
    deleteItem(){
      let fData = new FormData();
      fData.append("id",this.delete_data.id);
      axios.post("/admin/events-delete",fData).then(resp => {
        if (resp.data['result'] == true) {
          if(1==this.arr_data.length) this.arr_data=[];
          this.getData();
        }
      }).catch(()=>{}).finally(()=>{
        this.dialogs.DeleteItemDialog = false;
      });
    },
    confirmDeleteItem(data){
      if(this.filters.status_id == 3){
        this.delete_data=data;
        this.dialogs.DeleteItemDialog = true;
      }
    },
    claerAll(){
      this.dialogs.filterItemDialog = false;
      this.selectedCategory = [];
      this.selectedRate = [];
      this.filters = {}
      this.getData();
    },
    onFilter(){
      if(this.selectedRate.length > 0){
      this.filters['rate'] = JSON.parse(JSON.stringify(this.selectedRate))
      };
      if(this.selectedCategory.length > 0){
        this.filters['category_id'] = JSON.parse(JSON.stringify(this.selectedCategory));
      };

      this.real_filters = JSON.parse(JSON.stringify(this.filters));
      this.data_info.page=0;
      this.dialogs.filterItemDialog = false
      this.getData();
    },
    countCategory(data){
      const propOwn = Object.getOwnPropertyNames(data);
      return propOwn.length-1;
    },
    deleteFilter(item){
      switch(item){
        case 'rate':
          this.selectedRate = [];
          break;
        case 'category_id':
          this.selectedCategory = [];
        break;
        case 'rate':
        break;
        case 'rate':
        break;
      }
      delete this.filters[item];
      this.getData();
    },
  },
  mounted() {
    this.getInit();
    this.getData(1);
    this.getSearchCompany('');
    this.getSearchCountry('');
    this.getSearchCompanyFilters('');
  }
}
</script>

<style>
.companies__content {
  display: flex;
  gap: 24px;
}
</style>