<template>
  <Header></Header>
  <main>
    <BreadCrumbs  :exit="false" :loading="loadingСompleted" :post="arr_data"></BreadCrumbs>
    <event-content>
      <ViewImages class="mobile-none" v-if="loadingСompleted && windowWidth >= 768"  :all_photo="showAllPhoto" @all-photo="showAllPhoto = $event"
                  :images="arr_data[0].images" :loading="loadingСompleted" :company_id="arr_data[0].company_id"
                  :id="arr_data[0].id"></ViewImages>
      <ViewPhotosSlider class="mobile" v-if="loadingСompleted && windowWidth <= 768" :id="arr_data[0].id" :company_id="arr_data[0].company_id" :images="arr_data[0].images"></ViewPhotosSlider>
      <ContentPage>
        <template v-slot:left>
          <Block :loading="loadingСompleted">
            <template v-slot:left>
              <GeneralInformation v-if="loadingСompleted" :estimated_duration="arr_data[0].estimated_duration"
                                  :estimated_duration_type="arr_data[0].estimated_duration_type"
                                  :meeting_point_type="arr_data[0].meeting_point_type"
                                  :max_participants="arr_data[0].max_participants"
                                  :languages_name="arr_data[0].languages_name"></GeneralInformation>
            </template>
          </Block>
          <Block :loading="loadingСompleted">
            <template v-slot:left>
              <BlockDescription v-if="loadingСompleted" :title="'Overview'" :value="arr_data[0].overview">
              </BlockDescription>
            </template>
          </Block>
          <Block :loading="loadingСompleted">
            <template v-slot:left>
              <BlockList v-if="loadingСompleted" :title="'Additional Information'"
                         :arrayList="arr_data[0].additional_information"></BlockList>
            </template>
          </Block>
          <Block :loading="loadingСompleted">
            <template v-slot:left>
              <BlockDescription v-if="loadingСompleted" :title="'Meeting point'" :value="arr_data[0].meeting_point">
              </BlockDescription>
            </template>
          </Block>
          <Block :loading="loadingСompleted">
            <template v-slot:left>
              <BlockList v-if="loadingСompleted" :title="'Cancelation policy'"
                         :arrayList="arr_data[0].cancelation_policy"></BlockList>
            </template>
          </Block>
        </template>
        <template v-slot:right>
          <BookingForm v-if="loadingСompleted" :event="arr_data" :loading="loadingСompleted"></BookingForm>
        </template>
      </ContentPage>
    </event-content>
    <section class="top__event__container">
      <TopExpiriencesSection :posts="alsoLike" :title="'You may also like'" :isLoading="isLoading"></TopExpiriencesSection>
    </section>
    <ReviewsSection v-if="reviewsData.length > 0"
    v-model:filters="filters" 
    :average="averageData" 
    :ratings="ratingData" 
    :reviews="reviewsData"
    @openPhotos="openPhotos"
    ></ReviewsSection>
    <ViewAllPhotos 
    v-if="loadingСompleted && windowWidth >= 768" 
    :reviews="reviewsData" 
    :class="{ active: showAllPhoto }" 
    @exit="showAllPhoto = $event"
    :company_id="arr_data[0].company_id"
    :id="arr_data[0].id"
    :event_name="arr_data[0].event_name"
    :steps="this.steps"
    :viewCurrentImage="viewCurrentImage"
    >
    </ViewAllPhotos>
    <ViewAllPhotoMobile
    v-if="loadingСompleted && windowWidth <= 768" 
    :class="{ active: showAllPhoto }" 
    @exit="showAllPhoto = $event; viewCurrentImage = false"
    :company_id="arr_data[0].company_id"
    :id="arr_data[0].id"
    :viewCurrentImage="viewCurrentImage"
    ></ViewAllPhotoMobile>
  </main>
  <Footer></Footer>
</template>

<script>
import ViewPhotosSlider from "@/components/EventPage/ViewPhotosSlider.vue";
import BreadCrumbs from "@/components/MainPages/EventPage/BreadCrumb.vue";
import Header from "@/components/MainPages/Header.vue";
import Footer from "@/components/MainPages/Footer.vue";
import ViewImages from "@/components/EventPage/ViewPhotos.vue";
import BlockList from "@/components/MainPages/EventPage/BlockList.vue";
import GeneralInformation from "@/components/MainPages/EventPage/GeneralInformation.vue";
import ContentPage from "@/components/MainPages/EventPage/ContentPage.vue";
import ViewAllPhotos from "@/components/EventPage/ViewAllPhotos.vue";
import ViewAllPhotoMobile from "@/components/EventPage/ViewAllPhotoMobile.vue";

import EventContent from "@/components/Busines/NewEventPreview/EventContent.vue";
import BlockDescription from "@/components/MainPages/EventPage/BlockDescription.vue";
import Block from "@/components/MainPages/EventPage/BlockWrapper.vue";
import axios from "axios";
import BookingForm from "@/components/MainPages/EventPage/BookingForm.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import ReviewsSection from "@/components/MainPages/EventPage/ReviewsSection.vue";
import TopExpiriencesSection from "@/components/MainPages/HomePage/TopExpiriencesSection.vue";
import { mapState } from 'vuex';
export default {
  components: {
    ReviewsSection,
    BreadCrumb,
    Block,
    BlockDescription,
    EventContent,
    ViewAllPhotos,
    ContentPage,
    GeneralInformation,
    BlockList,
    ViewImages,
    Footer, Header, BreadCrumbs,
    BookingForm,
    TopExpiriencesSection,
    ViewPhotosSlider,
    ViewAllPhotoMobile
  },
  data() {
    return {
      loadPage:false,
      isLoading: true,
      showAllPhoto: false,
      loadingСompleted: false,
      id_page: this.$route.params.id,
      arr_data: [],
      reviewsData: [],
      ratingData :[],
      averageData:[],
      alsoLike: [],
      filters: {},
      steps: 1,
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    openPhotos(index){
      this.steps = 2;
      this.viewCurrentImage = index;
      this.showAllPhoto = true;
    },
    changeFilters(e){
        this.filters = e;
        this.getReviews();
    },
    getData(currency, language) {
      let fData = new FormData();
      fData.append("currency", currency || 'USD');
      fData.append("language", language || 'en');
      fData.append("id", this.id_page);
      fData.append("city", this.$route.params.city);
      fData.append("country", this.$route.params.country);
      axios.post("/get/event", fData).then(resp => {
        if (resp.data.result == true) {
          this.arr_data = resp.data.events;
          this.alsoLike = resp.data.also_like;
        }
      }).catch(() => {
      }).finally(() => {
        this.loadingСompleted = true;
      });
    },
    async updateWishlist(event) {
      try {
        let fData = new FormData();
        fData.append("isChecked", event.target.checked);
        fData.append("event_id", this.id_page);
        const response = await axios.post('/post/checked-wishlist', fData);
        console.log(response.data);
      } catch (error) {
        console.error('Error updating wishlist:', error);
      }
    },
    getReviews() {
      let fData = new FormData();
      for (const key in this.filters) {
        if (
          this.filters.hasOwnProperty(key) &&
          this.filters[key] !== null &&
          this.filters[key] !== undefined &&
          this.filters[key] !== '' &&
          !(Array.isArray(this.filters[key]) && this.filters[key].length === 0)
        ) {
          fData.append(key,JSON.stringify(this.filters[key]));
        }
      }
      fData.append("event_id", this.id_page);
      axios.post("/get/reviews", fData).then(resp => {
        if (resp.data.result == true) {
          this.reviewsData = resp.data.data;
        }
      }).catch(() => {
      }).finally(() => {

      });

    },
    getInit() {
      let fData = new FormData();
      fData.append("event_id", this.id_page);
      axios.post("/get/init", fData).then(resp => {
        if (resp.data.result == true) {
          this.ratingData = resp.data.ratings;
          this.averageData = resp.data.totalAverage;
        }
      }).catch(() => {
      }).finally(() => {

      });

    },
    recordVisitor() {
      try {
        let fData = new FormData();
        fData.append("event_id", this.$route.params.id);
        const response = axios.post('/post/event/recently-viewed',fData);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    loadData({ currency, lang }) {
      this.isLoading = true;
      Promise.all([
      this.getData(currency, lang),
      ]);
      this.isLoading = false;
      this.loadPage = true;
    },
    getURLParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const currency = urlParams.get('currency');
      const lang = urlParams.get('lang');
      return { currency, lang };
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    this.recordVisitor();
    this.getInit();
    this.getReviews();
    const urlParams = this.getURLParams();
    this.loadData(urlParams);
    window.addEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState(['currency','user','languages'])
  },
  watch: {
    filters: {
      handler(val, oldVal) {
        this.getReviews();
      },
      deep: true
    },
    currency() {
      if (this.loadPage) {
        this.loadData(this.getURLParams());
        console.log(111111111);
      }
    },
    languages() {
      if (this.loadPage) {
        this.loadData(this.getURLParams());
        console.log(2222222222);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped>
.mobile-768 {
  display: none;
}
.mobile{
  display: none;
}
section {
  padding-bottom: 72px;
}
@media screen and (max-width: 1024px) {
.tablet-none {
    display: none;
  }
  .tablet{
  display: block;
}
}
@media screen and (max-width: 768px) {
  .mobile-none {
    display: none;
  }
  .mobile{
  display: block;
}
}
</style>