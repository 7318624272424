<template>
  <header-page></header-page>
  <main>
    <section class="companies">
      <div class="companies__container">
        <div class="companies__content">
          <side-bar></side-bar>
          <div class="right__main__content">
            <div class="filter__content">
              <div class="filter_content_wrapper">
              <div class="picker-option">
                <template v-for="status in arr_status">
                <button :class="[status.status_name,{'active': filters.status_id == status.id}]"  @click="changeStatus(status.id)" type="button">
                  {{ status.status_name }}
                </button>
                <div class="arrow__top"></div>
                </template>
              </div>
              <ActiveFilters @delete="deleteFilter" :arrFilters="arrFilters" :filters="filters"></ActiveFilters>
              </div>
                <div class="filter">
                <input-filter @value="getSearchData" :placeholder_title="'Search for company'" :data_name="'company_name'" :sort="arr_company" @search="getSearchCompany"></input-filter>
                <filter-button @click="this.dialogs.filterItemDialog =  true"></filter-button>
              </div>
            </div>
            <div>

            <post-list class="active" :posts="arr_data" :loader="data_info.loading">
              <table class="list">
                <thead>
                <tr>
                <th>Company name</th>
                <th>Country</th>
                <th>Contact person</th>
                <th>Status</th>
                <th class="table__none">Registration date</th>
                <th>Rate</th>
                <th>Terms</th>
                <th></th>
                </tr>
              </thead>
                <tbody v-if="loadingСompleted">
              <tr v-for="post in this.arr_data"
                  :key="post.id">
                <td>
                  <div class="company__name">
                    <img v-if="post.image" :src='this.$store.state.base_directory + "company/"+ post.id + "/"+ post.image' alt="">
                    <div class="image_letter" :style="{ background: getBackgroundColor(post.company_name) }" v-else>{{ post.company_name.substr(0, 1) }}</div>
                    <div>{{ post.company_name }}</div>
                  </div>
                </td>
                <td>
                  <div class="table__name">Country:</div>
                  <div class="company__country">{{ post.country_name }}</div></td>
                <td>
                  <div class="table__name">Contact person:</div>
                  <div class="company__person">
                    <div class="company__personName">{{ post.name }}</div>
                  </div>
                </td>
                <td>
                  <div class="table__name">Status:</div>
                  <div class="company__status" :class="[ post.color ]">
                    <div>{{ post.status_name }}</div>
                  </div>
                </td>
                <td  class="mobile__none table__none">
                  <div class="company__registrationDate">{{ post.registration_date }}</div>
                </td>
                <td  class="mobile__none">
                  <div class="company__rate">
                    <div>
                      <div class="company__rateNumber">{{ post.rate }}</div>
                      <svg class="company__rateStar" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path d="M10.0013 3.11533C10.0263 3.06113 10.0663 3.01522 10.1166 2.98304C10.1669 2.95086 10.2254 2.93376 10.2851 2.93376C10.3448 2.93376 10.4032 2.95086 10.4535 2.98304C10.5038 3.01522 10.5438 3.06113 10.5688 3.11533L12.5044 7.31158C12.5268 7.36009 12.5613 7.40205 12.6045 7.43345C12.6477 7.46486 12.6982 7.48465 12.7513 7.49096L17.3394 8.03471C17.3987 8.04176 17.4548 8.06566 17.5009 8.10359C17.5471 8.14151 17.5814 8.19186 17.5998 8.24868C17.6182 8.3055 17.62 8.36641 17.6048 8.42419C17.5897 8.48197 17.5583 8.53421 17.5144 8.57471L14.1226 11.7122C14.0835 11.7485 14.0543 11.7942 14.0379 11.845C14.0215 11.8958 14.0184 11.9499 14.0288 12.0022L14.9288 16.5341C14.9405 16.5927 14.9352 16.6534 14.9134 16.7091C14.8917 16.7647 14.8544 16.813 14.806 16.8481C14.7577 16.8833 14.7003 16.9038 14.6406 16.9073C14.581 16.9108 14.5215 16.8971 14.4694 16.8678L10.4382 14.611C10.3914 14.5847 10.3387 14.5709 10.2851 14.5709C10.2314 14.5709 10.1787 14.5847 10.1319 14.611L6.10068 16.8672C6.04861 16.8964 5.98927 16.9101 5.92968 16.9066C5.8701 16.9031 5.81275 16.8826 5.76443 16.8476C5.71611 16.8125 5.67883 16.7644 5.65701 16.7088C5.63519 16.6533 5.62974 16.5926 5.6413 16.5341L6.5413 12.0022C6.55184 11.9498 6.54876 11.8955 6.53234 11.8447C6.51593 11.7938 6.48673 11.748 6.44755 11.7116L3.05505 8.57408C3.01134 8.53355 2.98014 8.48136 2.96513 8.42367C2.95011 8.36599 2.95191 8.30521 2.9703 8.24851C2.9887 8.19181 3.02293 8.14155 3.06895 8.10367C3.11497 8.06579 3.17087 8.04186 3.23005 8.03471L7.8188 7.49096C7.87196 7.48476 7.92264 7.46501 7.96598 7.4336C8.00932 7.40219 8.04386 7.36017 8.0663 7.31158L10.0013 3.11533Z" fill="#FFCE47"/>
                      </svg>
                    </div>
                  </div>
                </td>
                <td class="mobile__none">
                  <div class="company__terms">{{ post.terms }}%</div>
                </td>
                <td>
                  <div class="company__panelButton">
                    <button @click="confirmDetailsItem(post)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <g clip-path="url(#clip0_3118_41499)">
                          <path d="M16.3333 15.8333H4.66667V4.16667H10.5V2.5H4.66667C3.74167 2.5 3 3.25 3 4.16667V15.8333C3 16.75 3.74167 17.5 4.66667 17.5H16.3333C17.25 17.5 18 16.75 18 15.8333V10H16.3333V15.8333ZM12.1667 2.5V4.16667H15.1583L6.96667 12.3583L8.14167 13.5333L16.3333 5.34167V8.33333H18V2.5H12.1667Z" fill="#292929"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_3118_41499">
                            <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <button @click="chatWithOrganizator(post.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path d="M13 3.33334V9.16667H4.80835L3.83335 10.1417V3.33334H13ZM13.8334 1.66667H3.00002C2.54169 1.66667 2.16669 2.04167 2.16669 2.50001V14.1667L5.50002 10.8333H13.8334C14.2917 10.8333 14.6667 10.4583 14.6667 10V2.50001C14.6667 2.04167 14.2917 1.66667 13.8334 1.66667ZM18 5.00001H16.3334V12.5H5.50002V14.1667C5.50002 14.625 5.87502 15 6.33335 15H15.5L18.8334 18.3333V5.83334C18.8334 5.37501 18.4584 5.00001 18 5.00001Z" fill="#292929"/>
                      </svg>
                    </button>
                    <button @click="confirmDeleteItem(post)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 1V2H18.5V4H17.5V17C17.5 18.1 16.6 19 15.5 19H5.5C4.4 19 3.5 18.1 3.5 17V4H2.5V2H7.5V1H13.5ZM5.5 17H15.5V4H5.5V17ZM7.5 6H9.5V15H7.5V6ZM13.5 6H11.5V15H13.5V6Z" fill="#292929"/>
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
              </table>
            </post-list>
              <list-preloader v-if="!loadingСompleted"></list-preloader>
            <list-flip @update="onPage" v-if="data_info.loading || arr_data.length > 0" :class="{ active: loadingСompleted }" 
              :page="data_info.page" 
              :currenNumber="arr_data.length" 
              :allCount="data_info.allCount" 
              :next="data_info.next"></list-flip>
            </div>
          </div>
          <modal-dialog v-model:show="dialogs.BlockedItemDialog" :modal="true">
              <div>
                <div class="title">Block the company</div>
                <div class="text">Specify the reason why you want to block the company</div>
              </div>
              <textarea class="textarea" type="text" name="message" data-valid="message" required=""></textarea>
              <div class="btn">
                <Button class="white" @click="this.dialogs.BlockedItemDialog = false">Cancel</Button>
                <Button class="red" @click="changeBlocked">Block</Button>
              </div>
          </modal-dialog>
          <modal-dialog v-model:show="dialogs.DeclineItemDialog" :modal="true">
            <div>
              <div class="title">Decline company</div>
              <div class="text">Specify the reason why you refused to register the company on the platform</div>
            </div>
            <textarea class="textarea" type="text" name="message" data-valid="message" required=""></textarea>
            <div class="btn">
              <Button class="white" @click="this.dialogs.DeclineItemDialog = false">Cancel</Button>
              <Button class="red" @click="changeBlocked">Decline</Button>
            </div>
          </modal-dialog>
          <modal-dialog v-model:show="dialogs.DeleteItemDialog" :modal="true">
            <div>
              <div class="icon delete">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M23 11V12H28V14H27V27C27 28.1 26.1 29 25 29H15C13.9 29 13 28.1 13 27V14H12V12H17V11H23ZM15 27H25V14H15V27ZM17 16H19V25H17V16ZM23 16H21V25H23V16Z" fill="#EA2313"/>
                </svg>
              </div>
              <div class="title">Permanently delete company</div>
              <div class="text">Do you really want to delete the Starbucks company?</div>
            </div>
            <div class="btn">
              <Button class="white" @click="this.dialogs.DeleteItemDialog = false">Cancel</Button>
              <Button class="red" @click="deleteItem">Delete</Button>
            </div>
            <button class="exit-dialog" @click="this.dialogs.DeleteItemDialog = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
              </svg>
            </button>
          </modal-dialog>
          <modal-dialog v-model:show="dialogs.ChangeTermsDialog" :modal="true">
            <div>
              <div class="title">Deal conditions</div>
              <input class="input" type="text" v-model="change_terms_update" @input="change_terms_update = $event.target.value">
            </div>
            <div class="btn">
              <Button class="white" @click="this.dialogs.ChangeTermsDialog = false">Cancel</Button>
              <Button class="green" @click="changeTerms">Update</Button>
            </div>
            <button class="exit-dialog" @click="this.dialogs.ChangeTermsDialog = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
              </svg>
            </button>
          </modal-dialog>
          <dialog-filter v-model:show="dialogs.filterItemDialog" :modal="true">
            <div>
              <div class="title__filter">Filter</div>
              <div class="filters">
                <div class="selected">
                  <div class="title">Company</div>
                  <Select v-model="filters['id']" :placeholder_title="'Choose company'" :data_name="'company_name'" :sort="arr_company_filters" @search="getSearchCompanyFilters"></Select>
                </div>
                <div class="selected">
                  <div class="title">Country</div>
                  <Select v-model="filters['country_id']" :placeholder_title="'Choose country'" :data_name="'name'" :sort="arr_country" @search="getSearchCountry"></Select>
                </div>
                <div class="rate">
                  <div class="title">Rate</div>
                  <template v-for="rate in rates">
                    <div class="checkbox">
                      <input :id="rate.count" class="checkbox_input" :value="rate" type="checkbox" v-model="selectedRate" />
                      <label :for="rate.count"></label>
                      <div class="count_stars">
                        <svg v-for="n in rate.count" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M11.6586 3.73839C11.6886 3.67335 11.7367 3.61825 11.797 3.57964C11.8574 3.54103 11.9275 3.52051 11.9991 3.52051C12.0708 3.52051 12.1409 3.54103 12.2013 3.57964C12.2616 3.61825 12.3096 3.67335 12.3396 3.73839L14.6624 8.77389C14.6892 8.8321 14.7306 8.88245 14.7824 8.92014C14.8343 8.95782 14.895 8.98158 14.9586 8.98914L20.4644 9.64164C20.5356 9.65011 20.6028 9.67879 20.6582 9.7243C20.7136 9.7698 20.7547 9.83023 20.7768 9.89841C20.7989 9.9666 20.801 10.0397 20.7829 10.109C20.7647 10.1784 20.7271 10.241 20.6744 10.2896L16.6041 14.0546C16.5572 14.0982 16.5223 14.1531 16.5026 14.214C16.4829 14.2749 16.4791 14.3399 16.4916 14.4026L17.5716 19.8409C17.5857 19.9112 17.5793 19.9841 17.5532 20.0509C17.5271 20.1177 17.4823 20.1756 17.4243 20.2178C17.3663 20.2599 17.2974 20.2845 17.2258 20.2887C17.1542 20.2929 17.0829 20.2765 17.0204 20.2414L12.1829 17.5331C12.1268 17.5016 12.0635 17.485 11.9991 17.485C11.9348 17.485 11.8715 17.5016 11.8154 17.5331L6.97788 20.2406C6.9154 20.2757 6.84419 20.2921 6.77269 20.2879C6.70118 20.2837 6.63237 20.2592 6.57438 20.2171C6.5164 20.1751 6.47167 20.1173 6.44548 20.0506C6.4193 19.9839 6.41276 19.9112 6.42663 19.8409L7.50663 14.4026C7.51928 14.3397 7.51558 14.2747 7.49588 14.2136C7.47618 14.1525 7.44115 14.0975 7.39413 14.0539L3.32313 10.2889C3.27068 10.2403 3.23324 10.1776 3.21522 10.1084C3.1972 10.0392 3.19935 9.96624 3.22143 9.8982C3.24351 9.83017 3.28458 9.76986 3.33981 9.7244C3.39504 9.67894 3.46212 9.65023 3.53313 9.64164L9.03963 8.98914C9.10342 8.9817 9.16424 8.958 9.21624 8.92031C9.26825 8.88262 9.3097 8.8322 9.33663 8.77389L11.6586 3.73839Z" fill="#292929" stroke="#292929" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <svg v-for="n in 5-rate.count" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M11.6586 3.73839C11.6886 3.67335 11.7367 3.61825 11.797 3.57964C11.8574 3.54103 11.9275 3.52051 11.9991 3.52051C12.0708 3.52051 12.1409 3.54103 12.2013 3.57964C12.2616 3.61825 12.3096 3.67335 12.3396 3.73839L14.6624 8.77389C14.6892 8.8321 14.7306 8.88245 14.7824 8.92014C14.8343 8.95782 14.895 8.98158 14.9586 8.98914L20.4644 9.64164C20.5356 9.65011 20.6028 9.67879 20.6582 9.7243C20.7136 9.7698 20.7547 9.83023 20.7768 9.89841C20.7989 9.9666 20.801 10.0397 20.7829 10.109C20.7647 10.1784 20.7271 10.241 20.6744 10.2896L16.6041 14.0546C16.5572 14.0982 16.5223 14.1531 16.5026 14.214C16.4829 14.2749 16.4791 14.3399 16.4916 14.4026L17.5716 19.8409C17.5857 19.9112 17.5793 19.9841 17.5532 20.0509C17.5271 20.1177 17.4823 20.1756 17.4243 20.2178C17.3663 20.2599 17.2974 20.2845 17.2258 20.2887C17.1542 20.2929 17.0829 20.2765 17.0204 20.2414L12.1829 17.5331C12.1268 17.5016 12.0635 17.485 11.9991 17.485C11.9348 17.485 11.8715 17.5016 11.8154 17.5331L6.97788 20.2406C6.9154 20.2757 6.84419 20.2921 6.77269 20.2879C6.70118 20.2837 6.63237 20.2592 6.57438 20.2171C6.5164 20.1751 6.47167 20.1173 6.44548 20.0506C6.4193 19.9839 6.41276 19.9112 6.42663 19.8409L7.50663 14.4026C7.51928 14.3397 7.51558 14.2747 7.49588 14.2136C7.47618 14.1525 7.44115 14.0975 7.39413 14.0539L3.32313 10.2889C3.27068 10.2403 3.23324 10.1776 3.21522 10.1084C3.1972 10.0392 3.19935 9.96624 3.22143 9.8982C3.24351 9.83017 3.28458 9.76986 3.33981 9.7244C3.39504 9.67894 3.46212 9.65023 3.53313 9.64164L9.03963 8.98914C9.10342 8.9817 9.16424 8.958 9.21624 8.92031C9.26825 8.88262 9.3097 8.8322 9.33663 8.77389L11.6586 3.73839Z" stroke="#292929" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="btn">
              <Button class="white" @click="claerAll">Clear All</Button>
              <Button class="green" @click="onFilter">Show options</Button>
            </div>
            <Button class="exit-dialog" @click="this.dialogs.filterItemDialog = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
              </svg>
            </Button>
          </dialog-filter>
          <dialog-details v-model:show="dialogs.DetailsItemDialogAwaiting" :modal="true">
            <Button class="exit-dialog" @click="this.dialogs.DetailsItemDialogAwaiting = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
              </svg>
            </Button>
            <div class="content">
                <div class="title_modal">Company details</div>
                <div class="top_nav_bnt">
                  <div class="company_detail">
                    <img v-if="this.details_data['image']" :src='this.$store.state.base_directory + "company/"+ this.details_data.id + "/"+ this.details_data.image' alt="">
                    <div class="image_letter" :style="{ background: getBackgroundColor(details_data.company_name) }" v-else>{{ this.details_data.company_name.substr(0,1) }}</div>
                    <div class="company__name">{{ this.details_data.company_name }}</div>
                  </div>
                  <div class="company__panelButton">
                    <button @click="downloadDocuments(details_data.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M11.6071 4.16667L15.1849 8.19167V15.8333H4.81449V4.16667H11.6071ZM11.6071 2.5H4.81449C3.99967 2.5 3.33301 3.25 3.33301 4.16667V15.8333C3.33301 16.75 3.99967 17.5 4.81449 17.5H15.1849C15.9997 17.5 16.6663 16.75 16.6663 15.8333V8.19167C16.6663 7.75 16.5108 7.325 16.2293 7.01667L12.6515 2.99167C12.3775 2.675 11.9997 2.5 11.6071 2.5ZM6.29597 12.5H13.7034V14.1667H6.29597V12.5ZM6.29597 9.16667H13.7034V10.8333H6.29597V9.16667ZM6.29597 5.83333H11.4812V7.5H6.29597V5.83333Z" fill="#292929"/>
                      </svg>
                    </button>
                    <button @click="redirectTo(details_data.website)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10.0003 1.66666C5.40033 1.66666 1.66699 5.39999 1.66699 9.99999C1.66699 14.6 5.40033 18.3333 10.0003 18.3333C14.6003 18.3333 18.3337 14.6 18.3337 9.99999C18.3337 5.39999 14.6003 1.66666 10.0003 1.66666ZM3.33366 9.99999C3.33366 9.49166 3.40033 8.99166 3.50866 8.51666L7.49199 12.5V13.3333C7.49199 14.25 8.24199 15 9.15866 15V16.6083C5.88366 16.1917 3.33366 13.3917 3.33366 9.99999ZM14.9087 14.5C14.692 13.825 14.0753 13.3333 13.3253 13.3333H12.492V10.8333C12.492 10.375 12.117 9.99999 11.6587 9.99999H6.65866V8.33332H8.32533C8.78366 8.33332 9.15866 7.95832 9.15866 7.49999V5.83332H10.8253C11.742 5.83332 12.492 5.08332 12.492 4.16666V3.82499C14.9337 4.80832 16.667 7.20832 16.667 9.99999C16.667 11.7333 15.992 13.3167 14.9087 14.5Z" fill="#292929"/>
                      </svg>
                    </button>
                    <button @click="chatWithOrganizator(details_data.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.5003 3.33332V9.16666H4.30866L3.33366 10.1417V3.33332H12.5003ZM13.3337 1.66666H2.50033C2.04199 1.66666 1.66699 2.04166 1.66699 2.49999V14.1667L5.00033 10.8333H13.3337C13.792 10.8333 14.167 10.4583 14.167 9.99999V2.49999C14.167 2.04166 13.792 1.66666 13.3337 1.66666ZM17.5003 4.99999H15.8337V12.5H5.00033V14.1667C5.00033 14.625 5.37533 15 5.83366 15H15.0003L18.3337 18.3333V5.83332C18.3337 5.37499 17.9587 4.99999 17.5003 4.99999Z" fill="#292929"/>
                      </svg>
                    </button>
                    <button @click="dialogs.DetailsItemDialogAwaiting = false;  confirmDeleteItem(details_data)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 1V2H18.5V4H17.5V17C17.5 18.1 16.6 19 15.5 19H5.5C4.4 19 3.5 18.1 3.5 17V4H2.5V2H7.5V1H13.5ZM5.5 17H15.5V4H5.5V17ZM7.5 6H9.5V15H7.5V6ZM13.5 6H11.5V15H13.5V6Z" fill="#292929"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="all_details_info">
                  <div class="item">
                    <div class="left">Contact person:</div>
                    <div class="right">
                      <div class="text">{{ this.details_data.name }}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="left">Industry type:</div>
                    <div class="right">
                      <div class="text">Cofee Tours</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="left">Location:</div>
                    <div class="right">
                      <div class="text">{{ this.details_data.country_name }}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="left">Contact email:</div>
                    <div class="right">
                      <div class="text">{{this.details_data.person_email}}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="left">Registration date:</div>
                    <div class="right">
                      <div class="text">{{this.details_data.registration_date}}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="left">Company certificate:</div>
                    <div class="right">
                      <div class="text underlining">{{this.details_data.company_certificate}}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="left">Terms:</div>
                    <div class="right">
                      <div class="text">{{this.change_terms}}%</div>
                      <div class="company__panelButton">
                        <button @click="dialogs.DetailsItemDialogAwaiting = false; dialogs.ChangeTermsDialog = true">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M2.5 14.3746V17.4996H5.625L14.8417 8.28297L11.7167 5.15798L2.5 14.3746ZM17.2583 5.86631C17.5833 5.54131 17.5833 5.01631 17.2583 4.69131L15.3083 2.74131C14.9833 2.41631 14.4583 2.41631 14.1333 2.74131L12.6083 4.26631L15.7333 7.39131L17.2583 5.86631Z" fill="#292929"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="btn">
              <Button class="white" @click="dialogs.DetailsItemDialogAwaiting = false; confirmDeleteItem(details_data)">Decline</Button>
              <Button class="green" @click="dialogs.DetailsItemDialogAwaiting = false; changeApprove(details_data)">Approve</Button>
            </div>
          </dialog-details>
          <dialog-details v-model:show="dialogs.DetailsItemDialog" :modal="true">
            <Button class="exit-dialog" @click="this.dialogs.DetailsItemDialog = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
              </svg>
            </Button>
            <div class="content">
              <div class="title_modal">Company details</div>
              <div class="top_nav_bnt">
                <div class="company_detail">
                  <img v-if="this.details_data['image']" :src='this.$store.state.base_directory + "company/"+ this.details_data.id + "/"+ this.details_data.image' alt="">
                  <div class="image_letter" :style="{ background: getBackgroundColor(details_data.company_name) }" v-else>{{ this.details_data.company_name.substr(0,1) }}</div>
                  <div class="company__name">{{ this.details_data.company_name }}</div>
                </div>
                <div class="company__panelButton">
                  <button @click="downloadDocuments(details_data.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M11.6071 4.16667L15.1849 8.19167V15.8333H4.81449V4.16667H11.6071ZM11.6071 2.5H4.81449C3.99967 2.5 3.33301 3.25 3.33301 4.16667V15.8333C3.33301 16.75 3.99967 17.5 4.81449 17.5H15.1849C15.9997 17.5 16.6663 16.75 16.6663 15.8333V8.19167C16.6663 7.75 16.5108 7.325 16.2293 7.01667L12.6515 2.99167C12.3775 2.675 11.9997 2.5 11.6071 2.5ZM6.29597 12.5H13.7034V14.1667H6.29597V12.5ZM6.29597 9.16667H13.7034V10.8333H6.29597V9.16667ZM6.29597 5.83333H11.4812V7.5H6.29597V5.83333Z" fill="#292929"/>
                    </svg>
                  </button>
                  <button @click="redirectTo(details_data.website)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M10.0003 1.66666C5.40033 1.66666 1.66699 5.39999 1.66699 9.99999C1.66699 14.6 5.40033 18.3333 10.0003 18.3333C14.6003 18.3333 18.3337 14.6 18.3337 9.99999C18.3337 5.39999 14.6003 1.66666 10.0003 1.66666ZM3.33366 9.99999C3.33366 9.49166 3.40033 8.99166 3.50866 8.51666L7.49199 12.5V13.3333C7.49199 14.25 8.24199 15 9.15866 15V16.6083C5.88366 16.1917 3.33366 13.3917 3.33366 9.99999ZM14.9087 14.5C14.692 13.825 14.0753 13.3333 13.3253 13.3333H12.492V10.8333C12.492 10.375 12.117 9.99999 11.6587 9.99999H6.65866V8.33332H8.32533C8.78366 8.33332 9.15866 7.95832 9.15866 7.49999V5.83332H10.8253C11.742 5.83332 12.492 5.08332 12.492 4.16666V3.82499C14.9337 4.80832 16.667 7.20832 16.667 9.99999C16.667 11.7333 15.992 13.3167 14.9087 14.5Z" fill="#292929"/>
                    </svg>
                  </button>
                  <button @click="chatWithOrganizator(details_data.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M12.5003 3.33332V9.16666H4.30866L3.33366 10.1417V3.33332H12.5003ZM13.3337 1.66666H2.50033C2.04199 1.66666 1.66699 2.04166 1.66699 2.49999V14.1667L5.00033 10.8333H13.3337C13.792 10.8333 14.167 10.4583 14.167 9.99999V2.49999C14.167 2.04166 13.792 1.66666 13.3337 1.66666ZM17.5003 4.99999H15.8337V12.5H5.00033V14.1667C5.00033 14.625 5.37533 15 5.83366 15H15.0003L18.3337 18.3333V5.83332C18.3337 5.37499 17.9587 4.99999 17.5003 4.99999Z" fill="#292929"/>
                    </svg>
                  </button>
                  <button  @click="dialogs.DetailsItemDialog = false;  confirmDeleteItem(details_data)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 1V2H18.5V4H17.5V17C17.5 18.1 16.6 19 15.5 19H5.5C4.4 19 3.5 18.1 3.5 17V4H2.5V2H7.5V1H13.5ZM5.5 17H15.5V4H5.5V17ZM7.5 6H9.5V15H7.5V6ZM13.5 6H11.5V15H13.5V6Z" fill="#292929"/>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="all_details_info">
                <div class="item">
                  <div class="left">Contact person:</div>
                  <div class="right">
                    <div class="text">{{ this.details_data.name }}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Industry type:</div>
                  <div class="right">
                    <div class="text">Cofee Tours</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Location:</div>
                  <div class="right">
                    <div class="text">{{ this.details_data.country_name }}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Rate:</div>
                  <div class="right">
                    <div class="text">{{ this.details_data.rate }}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M9.7322 15.2317C9.89583 15.1278 10.1048 15.1278 10.2685 15.2317L14.1333 17.6872C14.5091 17.9259 14.9859 17.5916 14.8896 17.157L13.8463 12.4493C13.8075 12.2745 13.8652 12.0923 13.9975 11.9716L17.4933 8.78385C17.8148 8.49064 17.6343 7.95509 17.2009 7.91638L12.647 7.50969C12.4598 7.49298 12.2978 7.37273 12.2276 7.19842L10.4641 2.8186C10.296 2.40093 9.70468 2.40093 9.53651 2.8186L7.77302 7.19842C7.70284 7.37273 7.54085 7.49298 7.35368 7.50969L2.79977 7.91638C2.36634 7.95509 2.1858 8.49064 2.50734 8.78385L6.00311 11.9716C6.13541 12.0923 6.1931 12.2745 6.15436 12.4493L5.11103 17.157C5.01471 17.5916 5.49159 17.9259 5.86731 17.6872L9.7322 15.2317Z" fill="#FFCE47"/>
                    </svg>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Contact email:</div>
                  <div class="right">
                    <div class="text">{{this.details_data.person_email}}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Registration date</div>
                  <div class="right">
                    <div class="text">{{this.details_data.registration_date}}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Balance:</div>
                  <div class="right">
                    <div class="text">$ {{ details_data.total_order_sum }} USD  </div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Number of events:</div>
                  <div class="right">
                    <div class="text">{{ details_data.event_count }}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Terms:</div>
                  <div class="right">
                    <div class="text">{{this.change_terms}}%</div>
                    <div class="company__panelButton">
                      <button @click="dialogs.DetailsItemDialog = false; dialogs.ChangeTermsDialog = true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M2.5 14.3746V17.4996H5.625L14.8417 8.28297L11.7167 5.15798L2.5 14.3746ZM17.2583 5.86631C17.5833 5.54131 17.5833 5.01631 17.2583 4.69131L15.3083 2.74131C14.9833 2.41631 14.4583 2.41631 14.1333 2.74131L12.6083 4.26631L15.7333 7.39131L17.2583 5.86631Z" fill="#292929"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn">
            <Button class="white" @click="dialogs.DetailsItemDialog = false; confirmDeleteItem(details_data)">Block</Button>
            </div>
          </dialog-details>
          <dialog-details v-model:show="dialogs.DetailsItemDialogBlocked" :modal="true">
            <Button class="exit-dialog" @click="this.dialogs.DetailsItemDialogBlocked = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
              </svg>
            </Button>
            <div class="content">
              <div class="title_modal">Company details</div>
              <div class="top_nav_bnt">
                <div class="company_detail">
                  <img v-if="this.details_data['image']" :src='this.$store.state.base_directory + "company/"+ this.details_data.id + "/"+ this.details_data.image' alt="">
                  <div class="image_letter" :style="{ background: getBackgroundColor(details_data.company_name) }" v-else>{{ this.details_data.company_name.substr(0,1) }}</div>
                  <div class="company__name">{{ this.details_data.company_name }}</div>
                </div>
                <div class="company__panelButton">
                  <button @click="downloadDocuments(details_data.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M11.6071 4.16667L15.1849 8.19167V15.8333H4.81449V4.16667H11.6071ZM11.6071 2.5H4.81449C3.99967 2.5 3.33301 3.25 3.33301 4.16667V15.8333C3.33301 16.75 3.99967 17.5 4.81449 17.5H15.1849C15.9997 17.5 16.6663 16.75 16.6663 15.8333V8.19167C16.6663 7.75 16.5108 7.325 16.2293 7.01667L12.6515 2.99167C12.3775 2.675 11.9997 2.5 11.6071 2.5ZM6.29597 12.5H13.7034V14.1667H6.29597V12.5ZM6.29597 9.16667H13.7034V10.8333H6.29597V9.16667ZM6.29597 5.83333H11.4812V7.5H6.29597V5.83333Z" fill="#292929"/>
                    </svg>
                  </button>
                  <button @click="redirectTo(details_data.website)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M10.0003 1.66666C5.40033 1.66666 1.66699 5.39999 1.66699 9.99999C1.66699 14.6 5.40033 18.3333 10.0003 18.3333C14.6003 18.3333 18.3337 14.6 18.3337 9.99999C18.3337 5.39999 14.6003 1.66666 10.0003 1.66666ZM3.33366 9.99999C3.33366 9.49166 3.40033 8.99166 3.50866 8.51666L7.49199 12.5V13.3333C7.49199 14.25 8.24199 15 9.15866 15V16.6083C5.88366 16.1917 3.33366 13.3917 3.33366 9.99999ZM14.9087 14.5C14.692 13.825 14.0753 13.3333 13.3253 13.3333H12.492V10.8333C12.492 10.375 12.117 9.99999 11.6587 9.99999H6.65866V8.33332H8.32533C8.78366 8.33332 9.15866 7.95832 9.15866 7.49999V5.83332H10.8253C11.742 5.83332 12.492 5.08332 12.492 4.16666V3.82499C14.9337 4.80832 16.667 7.20832 16.667 9.99999C16.667 11.7333 15.992 13.3167 14.9087 14.5Z" fill="#292929"/>
                    </svg>
                  </button>
                  <button @click="chatWithOrganizator(details_data.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M12.5003 3.33332V9.16666H4.30866L3.33366 10.1417V3.33332H12.5003ZM13.3337 1.66666H2.50033C2.04199 1.66666 1.66699 2.04166 1.66699 2.49999V14.1667L5.00033 10.8333H13.3337C13.792 10.8333 14.167 10.4583 14.167 9.99999V2.49999C14.167 2.04166 13.792 1.66666 13.3337 1.66666ZM17.5003 4.99999H15.8337V12.5H5.00033V14.1667C5.00033 14.625 5.37533 15 5.83366 15H15.0003L18.3337 18.3333V5.83332C18.3337 5.37499 17.9587 4.99999 17.5003 4.99999Z" fill="#292929"/>
                    </svg>
                  </button>
                  <button  @click="dialogs.DetailsItemDialogBlocked = false;  confirmDeleteItem(details_data)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 1V2H18.5V4H17.5V17C17.5 18.1 16.6 19 15.5 19H5.5C4.4 19 3.5 18.1 3.5 17V4H2.5V2H7.5V1H13.5ZM5.5 17H15.5V4H5.5V17ZM7.5 6H9.5V15H7.5V6ZM13.5 6H11.5V15H13.5V6Z" fill="#292929"/>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="all_details_info">
                <div class="item">
                  <div class="left">Contact person:</div>
                  <div class="right">
                    <div class="text">{{ this.details_data.name }}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Industry type:</div>
                  <div class="right">
                    <div class="text">Cofee Tours</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Location:</div>
                  <div class="right">
                    <div class="text">{{ this.details_data.country_name }}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Rate:</div>
                  <div class="right">
                    <div class="text">{{ this.details_data.rate }}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M9.7322 15.2317C9.89583 15.1278 10.1048 15.1278 10.2685 15.2317L14.1333 17.6872C14.5091 17.9259 14.9859 17.5916 14.8896 17.157L13.8463 12.4493C13.8075 12.2745 13.8652 12.0923 13.9975 11.9716L17.4933 8.78385C17.8148 8.49064 17.6343 7.95509 17.2009 7.91638L12.647 7.50969C12.4598 7.49298 12.2978 7.37273 12.2276 7.19842L10.4641 2.8186C10.296 2.40093 9.70468 2.40093 9.53651 2.8186L7.77302 7.19842C7.70284 7.37273 7.54085 7.49298 7.35368 7.50969L2.79977 7.91638C2.36634 7.95509 2.1858 8.49064 2.50734 8.78385L6.00311 11.9716C6.13541 12.0923 6.1931 12.2745 6.15436 12.4493L5.11103 17.157C5.01471 17.5916 5.49159 17.9259 5.86731 17.6872L9.7322 15.2317Z" fill="#FFCE47"/>
                    </svg>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Contact email:</div>
                  <div class="right">
                    <div class="text">{{this.details_data.person_email}}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Registration date</div>
                  <div class="right">
                    <div class="text">{{this.details_data.registration_date}}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Balance:</div>
                  <div class="right">
                    <div class="text">$ {{ details_data.total_order_sum }} USD  </div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Number of events:</div>
                  <div class="right">
                    <div class="text">4</div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Terms:</div>
                  <div class="right">
                    <div class="text">{{this.change_terms}}%</div>
                    <div class="company__panelButton">
                      <button @click="dialogs.DetailsItemDialogBlocked = false; dialogs.ChangeTermsDialog = true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M2.5 14.3746V17.4996H5.625L14.8417 8.28297L11.7167 5.15798L2.5 14.3746ZM17.2583 5.86631C17.5833 5.54131 17.5833 5.01631 17.2583 4.69131L15.3083 2.74131C14.9833 2.41631 14.4583 2.41631 14.1333 2.74131L12.6083 4.26631L15.7333 7.39131L17.2583 5.86631Z" fill="#292929"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="left">Reason for blocks:</div>
                  <div class="right">
                    <div class="text">{{this.details_data.reason_for_block}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn">
              <Button class="white" @click="dialogs.DetailsItemDialogBlocked = false; confirmDeleteItem(details_data)">Delete</Button>
              <Button class="green" @click="dialogs.DetailsItemDialogBlocked = false; changeUnblocked(details_data)">Unlock</Button>
            </div>
          </dialog-details>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import PostList from "@/components/Admin/AdminPanelList.vue";
import PickerFilter from "@/components/Admin/AdminPickerFilter.vue";
import ListFlip from "@/components/Admin/ListFlip.vue";
import axios from "axios";
import PostItem from "@/components/Admin/AdminPanelListItem.vue";
import ListPreloader from "@/components/UI/Admin/ListPreloader.vue";
export default {
  components: {
    PostItem,
    PostList,
    PickerFilter,
    ListFlip,
    ListPreloader
  },
  data() {
    return {
      loadingСompleted: false,
      rates:[
        {name:'rate', count:5},
        {name:'rate', count:4},
        {name:'rate', count:3},
        {name:'rate', count:2},
        {name:'rate', count:1}
      ],
      arrFilters:{
        'id' : 'Company',
        'rate' : 'Rate',
        'country_id': 'Country',
      },
      selectedRate:[],
      arr_data: [],
      arr_status: [
                { status_name: 'Active', id: 1 },
                { status_name: 'Awaiting', id: 2 },
                { status_name: 'Blocked', id: 3 }
            ],
      arr_country:[],
      arr_company:[],
      arr_company_filters:[],
      filters: {
        'status_id': 1,
      },
      change_terms: '',
      change_terms_update: '',
      dialogs:{
        DetailsItemDialog: false,
        DetailsItemDialogAwaiting: false,
        DetailsItemDialogBlocked: false,
        DeclineItemDialog: false,
        AddItemDialog:false,
        DeleteItemsDialog:false,
        DeleteItemDialog:false,
        BlockedItemDialog:false,
        ChangeItemDialog:false,
        filterItemDialog: false,
        ChangeTermsDialog: false,
      },
      sortCompany: [],
      delete_data:{},
      details_data:{
        'company_name': ''
      },
      changeBlocked_data:{},
      data_info:{
        loading: true,
        totalRecords: 0,
        page:0,
        next: true,
        allCount: null,
      },
    }
  },
  methods: {
    getBackgroundColor(name) {
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    }, 
    // async downloadDocuments(id) {
    //   const companyId = id; // Замените на ID вашей компании
    //   try {
    //     const response = await axios.get(`/download-documents/${companyId}`, {
    //       responseType: 'blob', // Очень важно для обработки бинарных данных
    //     });

    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', 'documents.zip');
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   } catch (error) {
    //     console.error('Error downloading documents', error);
    //   }
    // },
    downloadDocuments(id) {
      axios.get(`/admin/download-documents/${id}`)
        .then(response => {
          const documents = response.data;
          documents.forEach(doc => {
            this.downloadFile(doc.file_name);
          });
        })
        .catch(error => {
          console.error("There was an error downloading the documents!", error);
        });
    },
    async downloadFile(url) {
      const response = await fetch(this.$store.state.base_directory+url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = this.$store.state.base_directory+url;
      link.download = url.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    redirectTo(url) {
      window.open(url, '_blank');
    },
    chatWithOrganizator(to_id){
        let fData = new FormData();
        fData.append("to_id", to_id);
        axios.post("/admin/companies/chat-organizator", fData).then(resp => {
            this.$router.push({
                name: 'AdminMessage',
                query: { chat_id: resp.data.data }
            });
        }).catch(() => {
        }).finally(() => {
        });
    },
    getData(){
      this.loadingСompleted = false;
      let fData = new FormData();
      if(this.arr_data.length<=0){
        this.data_info.page--;
        if(this.data_info.page<0) this.data_info.page = 0;
      }
      fData.append("page",this.data_info.page);
      fData.append("filters",JSON.stringify(this.filters));
      axios.post("/admin/companies",fData).then(resp => {
        if (resp.data['result'] == true) {
         this.arr_data = resp.data.data;
         this.data_info.page = resp.data.page;
         this.data_info.allCount = resp.data.allCount;
         this.data_info.next = resp.data.next;
         this.data_info.loading = false;
        }
      }).catch(()=>{}).finally(()=>{
        this.loadingСompleted = true;
      });
    },
    getSearchData(e){
      if (e == '') delete this.filters['id'];
      else this.filters['id'] = e;
      this.getData();
    },
    getSearchCountry(e){
        let fData = new FormData();
        fData.append("name",e);
        axios.post("/admin/companies-country-filter",fData).then(resp => {
          if (resp.data['result'] == true) {
            this.arr_country = resp.data.country;
          }
        }).catch(()=>{}).finally(()=>{});
    },
    getSearchCompanyFilters(e){
      let fData = new FormData();
      fData.append("name",e);
      axios.post("/admin/companies-company-filter",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.arr_company_filters = resp.data.company;
        }
      }).catch(()=>{}).finally(()=>{});
    },
    getSearchCompany(e){
      let fData = new FormData();
      fData.append("name",e);
      fData.append("status_id",this.filters.status_id);
      axios.post("/admin/companies-company-filter",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.arr_company = resp.data.company;
        }
      }).catch(()=>{}).finally(()=>{});
    },
    claerAll(){
      this.dialogs.filterItemDialog = false
      this.filters = { status_id: this.filters.status_id };
      this.getData();
    },
    // getCountry(){
    //   axios.get('/companies-country')
    //       .then((response) => {
    //         for(let i in response.data){
    //           this.arr_country[response.data.id] = response.data.name
    //         }
    //         this.arr_country = response.data;
    //       })
    // },
    onPage(e){
        this.data_info.loading = true;
        this.data_info.page = e;
        this.getData();
    },
    changeStatus(e){
      this.data_info.loading = true;
      this.filters.status_id = e;
      this.getData();
    },
    confirmDeleteItem(data){
      if(this.filters.status_id == 1){
        this.changeBlocked_data = data;
        this.dialogs.BlockedItemDialog = true;
      }else if(this.filters.status_id == 2){
        this.changeBlocked_data=data;
        this.dialogs.DeclineItemDialog = true;
      } else if(this.filters.status_id == 3){
        this.delete_data=data;
        this.dialogs.DeleteItemDialog = true;
      }
    },
    confirmDetailsItem(data){
      if(this.filters.status_id == 1){
        this.changeBlocked_data = data;
        this.details_data = data;
        this.change_terms = this.details_data.terms
        this.change_terms_update =this.details_data.terms;
        this.dialogs.DetailsItemDialog = true;
      }else if(this.filters.status_id == 2){
        this.changeBlocked_data = data;
        this.details_data = data;
        this.change_terms = this.details_data.terms
        this.change_terms_update =this.details_data.terms;
        this.dialogs.DetailsItemDialogAwaiting = true;
      } else if(this.filters.status_id == 3){
        this.changeBlocked_data = data;
        this.details_data = data;
        this.delete_data=data;
        this.change_terms = this.details_data.terms;
        this.change_terms_update =this.details_data.terms;
        this.dialogs.DetailsItemDialogBlocked = true;
      }
    },
    changeTerms(data){
      let fData = new FormData();
      this.change_terms = this.change_terms_update;
      fData.append("id",this.changeBlocked_data.id);
      fData.append("terms",this.change_terms);
      axios.post("/admin/companies/change-terms",fData).then(resp => {
        console.log(resp);
        if (resp.data['result'] == true) {
          this.getData();
        }
      }).catch(()=>{}).finally(()=>{this.dialogs.ChangeTermsDialog = false;});
    },
    deleteItem(){
      let fData = new FormData();
      fData.append("id",this.delete_data.id);

      axios.post("/admin/companies/delete",fData).then(resp => {
        if (resp.data['result'] == true) {
          if(1==this.arr_data.length) this.arr_data=[];
          this.getData();
        }
      }).catch(()=>{}).finally(()=>{
        this.dialogs.DeleteItemDialog = false;
      });
    },
    changeBlocked(){
      let fData = new FormData();

      fData.append("status_id",3);
      fData.append("id",this.changeBlocked_data.id);
      axios.post("/admin/companies/blocked",fData).then(resp => {
        console.log(resp);
        if (resp.data['result'] == true) {
          this.getData();
        }
      }).catch(()=>{}).finally(()=>{
        this.dialogs.BlockedItemDialog = false;
        this.dialogs.DeclineItemDialog = false;
      });
    },
    changeUnblocked(){
      let fData = new FormData();
      fData.append("id",this.changeBlocked_data.id);
      axios.post("/admin/companies/unblocked",fData).then(resp => {
        console.log(resp);
        if (resp.data['result'] == true) {
          this.getData();
        }
      }).catch(()=>{}).finally(()=>{
      });
    },
    changeApprove(){
      let fData = new FormData();
      fData.append("id",this.changeBlocked_data.id);
      axios.post("/admin/companies/approve",fData).then(resp => {
        console.log(resp);
        if (resp.data['result'] == true) {
          this.getData();
        }
      }).catch(()=>{}).finally(()=>{
      });
    },
    onFilter(){
      if(this.selectedRate.length > 0){
      this.filters['rate'] = JSON.parse(JSON.stringify(this.selectedRate))
      }else{
        delete this.filters['rate']; 
        this.selectedRate = [];
      }
      this.data_info.page=0;
      this.dialogs.filterItemDialog = false
      this.getData();
    },
    deleteFilter(item){
      switch(item){
        case 'rate':
          this.selectedRate = [];
          break;
      }
      delete this.filters[item];
      this.getData();
    },
  },
  mounted() {
    this.getData(1);
    this.getSearchCompany('');
    this.getSearchCompanyFilters('');
    this.getSearchCountry('');
  }
}
</script>
<style>
.picker-option{
  max-width: unset !important;
}
.companies__content {
  display: flex;
  gap: 24px;
}
.right__main__content {
  width: 100%;
}
.right__main__content > svg{
  width: 100px;
  height: 100px;
  margin: 20px;
  display:inline-block;
}
.filter__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
  gap: 71px;
}


.picker-option {
  display: flex;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: var(--white-100, #FFF);
  align-items: center;
  padding: 2px 2px;
  max-width: max-content;
}
.picker-option .arrow__top {
  border-radius: 0.5px;
  background: rgba(84, 84, 88, 0.65);
  width: 0.5px;
  height: 18.284px;
  margin: 0 -1px;
}
.picker-option .arrow__top:last-of-type{
display: none;
}
.picker-option button {
  width: 85px;
  height: 32px;
  color: var(--gray-300, #888);
  text-align: center;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.08px;
  cursor: pointer;
}
.picker-option button.active {
  border-radius: 7px;
  background: var(--black-300, #333);
  color: var(--label-color-dark-primary, #FFF);
  text-align: center;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.08px;
}

.filter {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 36px;
  justify-content: flex-end;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .companies__content{
    flex-direction: column;
  }
  .picker-option{
    width: 100%;
    height: 40px;
  }
  .picker-option button{
    width: 100%;
    height: 100%;

    color: #888;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.08px;
  }
  .picker-option button.active{
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.08px;
  }
  .filter{
    width: 100%;
    height: 100%;
  }
  .filter:has(.input__filter__wrapper.open){
    position: relative;
  }
  .filter__content{
    flex-direction: column;
    gap: 16px;
  }
  .custom-select{
    width: max-content;
    height: 48px;
    padding: 7px 10px;
  }

  .content .all_details_info{
    overflow-y: overlay;
    max-height: 90%;
  }
}
</style>