<template>
  <Header></Header>
  <main>
    <BreadCrumbs class="mobile-none" :isLoading="isLoading"></BreadCrumbs>
    <section v-if="bannerData.length > 1 && windowWidth >= 768" class="slider__container">
      <SliderSection :array="bannerData" :isLoading="isLoading"></SliderSection>
    </section>
    <section v-else-if="bannerData.length <= 1" class="first__banner__container">
      <FirstBannerPage :array="bannerData" :isLoading="isLoading"></FirstBannerPage>
    </section>
    <section v-if="bannerData.length > 1 && windowWidth <= 768" class="banner__mobile">
      <FirstBannerMobile :array="bannerData" :isLoading="isLoading"></FirstBannerMobile>
    </section>
    <section class="tabs__filters__container">
      <CategoryFilters 
      :current_tab="filters.type" 
      :current_category="filters.category" 
      :categories="categories" 
      @change_category="handleCategoryChange" 
      @change_type="handleTabChange"
      :isLoading="isLoading"
      v-model:dialog="dialogs.dialogFilter"
      >
      <DialogFilters v-if="!isLoading"
      @filters="updateFilters" 
      :category="categories" 
      v-model:show="dialogs.dialogFilter"
      :current_tab="filters.type"
      :modal="true" >
      </DialogFilters>
    </CategoryFilters>
    </section>
    <template v-for="(item,index) in contentData">
      <section class="recently__viewed__container">
        <TopExpiriencesPage :title="'Top '+getCategoryName(index)" :posts="item" :isLoading="isLoading"></TopExpiriencesPage>
      </section>
    </template>
  </main>
  <Footer></Footer>
</template>

<script>
import BreadCrumbs from "@/components/MainPages/BreadCrumbs.vue";
import Header from "@/components/MainPages/Header.vue";
import FirstBannerPage from "@/components/MainPages/FirstBannerPage.vue";
import TopExpiriencesPage from "@/components/MainPages/HomePage/TopExpiriencesSection.vue";
import Footer from "@/components/MainPages/Footer.vue";
import CategoryFilters from "@/components/MainPages/CityPage/CategoryFilters.vue";
import SliderSection from "@/components/MainPages/HomePage/SliderSection.vue";
import FirstBannerMobile from "@/components/MainPages/HomePage/FirstBannerMobile.vue";
import DialogFilters from "@/components/MainPages/CityPage/DialogFilters.vue";
import axios from "axios";
import { mapState } from 'vuex';
export default {
  components: {CategoryFilters, Footer, TopExpiriencesPage, FirstBannerPage, Header, BreadCrumbs,SliderSection,FirstBannerMobile,DialogFilters},

  data() {
    return {
      loadPage: false,

      isLoading: true,
      categories: [],
      current_tab: 1,
      bannerData: [],
      contentData: [],
      filters: {
        minPrice: '',
        maxPrice: '',
        category: '',
        eventDuration: [],
        chooseLocation: [],
        timeDay: [],
        type: [],
      },
      dialogs: {
        dialogFilter: false,
      },
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    getCategoryName(categoryId) {
      const category = this.categories.find(category => category.id == categoryId);
      return category ? category.name : '';
    },
    async getBanner(){
      let fData = new FormData();
      const urlWithoutParams = window.location.origin + window.location.pathname;
      fData.append("lang", "en");
      fData.append("pageUrl", urlWithoutParams);
      fData.append("city", this.$route.params.city);
      await axios.post("/get/city/banner", fData)
          .then(resp => {
            this.bannerData = resp.data.data;
          })
          .catch(() => {
          })
          .finally(() => {
          });
    },
    async getCategories() {
      let fData = new FormData();
      
      fData.append("type", this.filters.type);
      await axios.post("/get/city/categories", fData)
          .then(resp => {
            this.categories = resp.data.data;
          })
          .catch(() => {
          })
          .finally(() => {
          });
    },
    updateFilters(e){
      const updatedFilters = { ...this.filters };
      for (const key in this.filters) {
        if (e.hasOwnProperty(key)) {
            updatedFilters[key] = e[key];
        }
      }
      this.filters = updatedFilters;
      this.getContent();
    },
    async getContent(currency, language) {
      let fData = new FormData();
        for (const key in this.filters) {
        if (
          this.filters.hasOwnProperty(key) &&
          this.filters[key] !== null &&
          this.filters[key] !== undefined &&
          this.filters[key] !== '' &&
          !(Array.isArray(this.filters[key]) && this.filters[key].length === 0)
        ) {
          fData.append(key,this.filters[key]);
        }
        
      }
      fData.append("currency", currency || 'USD'); // Используйте значение по умолчанию, если currency не определен
      fData.append("language", language || 'en');
      fData.append("city", this.$route.params.city);
      fData.append("country", this.$route.params.country);
      await axios.post("/get/city/content", fData)
          .then(resp => {
            this.contentData = resp.data.data;
          })
          .catch(() => {
          })
          .finally(() => {
          });
    },
    handleTabChange(tabData) {
      this.filters.type = tabData;
      this.filters.category = '';
      this.loadContent();
    },
    handleCategoryChange(tabData) {
      this.filters.category = tabData;
      this.loadContent();
    },
    onCityChange() {
      window.location.reload();
    },
    async loadContent() {
      this.isLoading = true;
      await Promise.all([
        this.getCategories(),
        this.getContent(),
      ]);
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
    },
    async loadData({ currency, lang }) {
      this.isLoading = true;
      await Promise.all([
        this.getCategories(),
        this.getBanner(),
        this.getContent(currency, lang),
      ])
      .then(results => {
      this.$nextTick(() => {
        this.waitForImagesToLoad();
      });
      }, error => {
          setTimeout(() => {
          this.isLoading = false;
          this.loadPage = true;
        }, 100);
        console.error("Error loading data:", error);
      });

    },
    waitForImagesToLoad() {
      const images = document.querySelectorAll('img[data-load]');
      const promises = [];

      images.forEach((img, index) => {
        if (!img.complete) {
          promises.push(new Promise((resolve) => {
            img.onload = () => {
              resolve();
            };
            img.onerror = () => {
              resolve(); // Считаем изображение загруженным даже при ошибке
            };
          }));
        } else {
        }
      });

      if (promises.length > 0) {
        Promise.all(promises).then(() => {
          setTimeout(() => {
            this.isLoading = false;
            this.loadPage = true;
          }, 100);
        });
      } else {
          setTimeout(() => {
          this.isLoading = false;
          this.loadPage = true;
        }, 100);
      }
    },
    getURLParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const currency = urlParams.get('currency');
      const lang = urlParams.get('lang');
      return { currency, lang };
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("type")) {
      this.filters.type = urlParams.get("type");
    } else {
      this.filters.type = 1;
      const url = new URL(window.location.href);
      url.searchParams.set("type", 1);
      window.history.pushState({}, "", url);
    }
    if (urlParams.has("category")) {
      this.filters.category = urlParams.get("category");
    }
    for (const key in this.filters) {
      if (urlParams.has(key)) {
        const value = urlParams.get(key);
        if(key == 'eventDuration'){
            
            if (value.includes(',')) {
              const array = value.split(',');
              this.filters[key] = array;
            } else {
              this.filters[key].push(value);
          }
        }
        else if(key == 'chooseLocation'){
            if (value.includes(',')) {
              const array = value.split(',');
              this.filters[key] = array;
            } else {
              this.filters[key].push(value);
          }
        }
        else if(key == 'timeDay'){
              if (value.includes(',')) {
              this.filters[key] = value.split(',');
            } else {
              this.filters[key].push(value);
          }
        }
        else {
          this.filters[key] = value;
        }
      }
    }
    const urlParamsData = this.getURLParams();
    this.loadData(urlParamsData);
    window.addEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState(['currency','user','languages'])
  },
  watch: {
      currency() {
        if (this.loadPage) this.loadData(this.getURLParams());
      },
      languages() {
        if (this.loadPage) this.loadData(this.getURLParams());
      },
      user() {
        if (this.loadPage) this.loadData(this.getURLParams());
      },
    '$route.params.city': 'onCityChange'
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped>
.mobile-768 {
  display: none;
}
section {
  padding-bottom: 72px;
}

.first__banner__container {
  padding-bottom: 24px;
}

.tabs__filters__container {
  padding-bottom: 36px;
}

@media screen and (max-width: 768px) {
  .mobile-none {
    display: none;
  }
  .mobile-768 {
    display: flex;
  }
  .first__banner__container {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 24px;
  }

  .tabs__filters__container {
    padding-bottom: 24px;
  }

  main {
    margin-top: 72px;
    margin-bottom: 0;
  }
  section:first-of-type {
    padding-bottom: px;
  }
}
</style>