<template>
    <div class="massages__wrapper">
      <div class="massage__item" v-for="chat in chatList" :class="{ active: chat.id == selectChat}" @click="changeChat(chat.id)" >
        <div class="left__content">
          <div class="image">
            <div v-if="chat.user.online === 2" class="status"></div>
            <img v-if="chat.user.image && chat.user.type == 1" :src='this.$store.state.base_directory + "admins/"+ chat.user.id + "/"+ chat.user.image' alt="">
            <img v-else-if="chat.user.image && chat.user.type == 2" :src='this.$store.state.base_directory + "company/"+ chat.user.id + "/"+ chat.user.image' alt="">
            <img v-else-if="chat.user.image && chat.user.type == 3" :src='this.$store.state.base_directory + "users/"+ chat.user.id + "/"+ chat.user.image' alt="">
            <!-- <div v-else class="image_letter" :style="{ background: '#' + (Math.random().toString(16) + '000000').substring(2,8).toUpperCase()}" >{{ chat.user.name.substr(0, 1) }}</div>-->
            <div v-else class="image_letter" :style="{ background: getBackgroundColor(chat.user.name) }">{{ chat.user.name.substr(0, 1) }}</div> 
          </div>
          <div class="massage__info">
            <div v-if="chat.user.type == 1" class="name">{{ chat.user.name }} {{ chat.user.last_name }}</div>
            <div v-else-if="chat.user.type == 2" class="name">{{ chat.user.company_name }}</div>
            <div v-else-if="chat.user.type == 3" class="name"><span>ID {{chat.id}}</span> {{ chat.user.name ? '('+chat.user.name+''+(chat.user.last_name ? ' '+chat.user.last_name : '')+')' : false}}</div>
            <div class="last__massage" v-if="chat.message.content">{{ chat.message.content }}</div>
          </div>
        </div>
        <div class="right__content" v-if="chat.message.content">
          <div class="last__massage__time">{{ chat.message.created_at }}</div>
          <div class="count" v-if="chat.unread != 0">{{ chat.unread }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'chat-list',
    props: {
      chatList: Object,
      selectChat: Number,
    },
    methods: {
      changeChat(id) {
        this.$emit('changeChat',id);
      },
      getBackgroundColor(name) {
      // Простейшая хэш-функция для генерации цвета на основе имени
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    },
    },
  }
  </script>
  
  <style scoped>
  .massage__item.active{
  background: #E4E4E4;
}
  .status{
    background: #00D348;
    border: 1.5px solid #fff;
    border-radius: 50%;
  
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
  }
  .massages__wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .massage__item{
    display: flex;
    justify-content: space-between;
    height: 72px;
    border-radius: 12px;
    border: 1.5px solid var(--gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
    padding: 12px;
    padding-left: 10px;
  }
  .left__content{
    display: flex;
    gap: 12px;
  }
  .left__content .image{
    position: relative;
  }
  .left__content .image img{
    width: 48px;
    height: 48px;
    border-radius: 37.5px;
  }
  .left__content .image .image_letter{
    width: 48px;
    height: 48px;
    border-radius: 37.5px;
    display: flex;
    align-items: center;
    justify-content: center;
  
  
    color: #FFF;
    text-align: center;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  
  .left__content .massage__info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 170px;
  }
  .massage__info .name{
    color: var(--black-400, #292929);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
  }
  .massage__info .name span{
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    text-decoration-line: underline;
  }
  .massage__info .last__massage{
    color: var(--gray-400, #606060);
    font-family: Standerd;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 153.846% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .right__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  .right__content .last__massage__time{
    color: var(--gray-400, #606060);
    text-align: right;
    font-family: Standerd;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 153.846% */
  }
  .right__content .count{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 4px 6px;
    background: var(--red-100, #F72314);
    width: 20px;
    height: 20px;
  
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Standerd;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.072px;
  }
  @media screen and (max-width: 1024px) {
    .massages__wrapper {
      overflow-y: auto;
      max-height: calc(79vh - 164px);
    }
  
  }
  </style>