<template>
  <div class="form__booking" :class="{ unactive: !loading}">
    <div class="booking_details" v-if="loading">
      <div class="price_person"><span>{{ this.$store.state.currency.symbol +' '+ event[0].price }}</span>per person</div>
      <form action="">
        <div class="title">Booking details</div>
        <div class="input__wrapper">
          <div class="input" @click="getEventDateTime" v-click-outside="() => hideModal('dialogCalendarPicker')">
            <div class="input-picker" type="text"> {{ formParams.updateData != ''? formatDate(this.formParams.updateData) : "Date" }}</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clip-path="url(#clip0_1720_35924)">
                <path
                    d="M14.1667 9.99967H10V14.1663H14.1667V9.99967ZM13.3333 0.833008V2.49967H6.66667V0.833008H5V2.49967H4.16667C3.24167 2.49967 2.50833 3.24967 2.50833 4.16634L2.5 15.833C2.5 16.7497 3.24167 17.4997 4.16667 17.4997H15.8333C16.75 17.4997 17.5 16.7497 17.5 15.833V4.16634C17.5 3.24967 16.75 2.49967 15.8333 2.49967H15V0.833008H13.3333ZM15.8333 15.833H4.16667V6.66634H15.8333V15.833Z"
                    fill="#333333"/>
              </g>
              <defs>
                <clipPath id="clip0_1720_35924">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <CalendarPicker 
            v-model:show="dialogs.dialogCalendarPicker" 
            :modal="true"
            :value_start="changeDataEvent.start_date"
            :value_end="changeDataEvent.end_date" @add="dateConfirm"
            />
          </div>
          <span v-if="v$.formParams.updateData.$invalid && submitted">Enter the field</span>
          <div class="input" @click="!v$.formParams.updateData.$invalid ? dialogs.modalChooseTrevelers = !dialogs.modalChooseTrevelers : false"
          v-click-outside="() => hideModal('modalChooseTrevelers')">
            <div class="input-picker" type="text">
                        {{
                          formParams.updateTravelers.adults >= 2 ?
                          formParams.updateTravelers.adults + ' Adults' :
                          formParams.updateTravelers.adults == 1 ? formParams.updateTravelers.adults + ' Adult' : ''
                        }}
                        {{
                          formParams.updateTravelers.child >= 2 ? ', ' +
                          formParams.updateTravelers.child + ' Childs' :
                          formParams.updateTravelers.child == 1 ? ', ' + formParams.updateTravelers.child  + ' Child' :
                          ''
                        }}
                        {{ formParams.updateTravelers == '' ? 'Traveler' : '' }}
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none">
              <path d="M10 5.625C11.3583 5.625 12.5583 5.95 13.5333 6.375C14.4333 6.775 15 7.675 15 8.65V10H5V8.65833C5 7.675 5.56667 6.775 6.46667 6.38333C7.44167 5.95 8.64167 5.625 10 5.625ZM3.33333 5.83333C4.25 5.83333 5 5.08333 5 4.16667C5 3.25 4.25 2.5 3.33333 2.5C2.41667 2.5 1.66667 3.25 1.66667 4.16667C1.66667 5.08333 2.41667 5.83333 3.33333 5.83333ZM4.275 6.75C3.96667 6.7 3.65833 6.66667 3.33333 6.66667C2.50833 6.66667 1.725 6.84167 1.01667 7.15C0.4 7.41667 0 8.01667 0 8.69167V10H3.75V8.65833C3.75 7.96667 3.94167 7.31667 4.275 6.75ZM16.6667 5.83333C17.5833 5.83333 18.3333 5.08333 18.3333 4.16667C18.3333 3.25 17.5833 2.5 16.6667 2.5C15.75 2.5 15 3.25 15 4.16667C15 5.08333 15.75 5.83333 16.6667 5.83333ZM20 8.69167C20 8.01667 19.6 7.41667 18.9833 7.15C18.275 6.84167 17.4917 6.66667 16.6667 6.66667C16.3417 6.66667 16.0333 6.7 15.725 6.75C16.0583 7.31667 16.25 7.96667 16.25 8.65833V10H20V8.69167ZM10 0C11.3833 0 12.5 1.11667 12.5 2.5C12.5 3.88333 11.3833 5 10 5C8.61667 5 7.5 3.88333 7.5 2.5C7.5 1.11667 8.61667 0 10 0Z" fill="#292929"/>
            </svg>
            <modalChooseTrevelers v-model:value="v$.formParams.updateTravelers.$model" v-model:show="dialogs.modalChooseTrevelers" :modal="true"></modalChooseTrevelers>
          </div>
          <span v-if="v$.formParams.updateTravelers.$invalid && submitted">Enter the field</span>
          <div class="input" @click="!v$.formParams.updateData.$invalid && !v$.formParams.updateTravelers.$invalid ? dialogs.modalChooseTime = !dialogs.modalChooseTime : false"
          v-click-outside="() => hideModal('modalChooseTime')">
            <div class="input-picker" type="text">{{ formParams.updateTime.id ? formatTimeToAMPM(formParams.updateTime.id) : "Time" }}</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M8.99935 0.667969C4.41602 0.667969 0.666016 4.41797 0.666016 9.0013C0.666016 13.5846 4.41602 17.3346 8.99935 17.3346C13.5827 17.3346 17.3327 13.5846 17.3327 9.0013C17.3327 4.41797 13.5827 0.667969 8.99935 0.667969ZM12.4994 12.5013L8.16602 9.83464V4.83464H9.41602V9.16797L13.166 11.418L12.4994 12.5013Z" fill="#292929"/>
          </svg>
            <modalChooseTime v-model:value="v$.formParams.updateTime.$model" v-model:show="dialogs.modalChooseTime" :data="changeTimeEvent" :modal="true"></modalChooseTime>
          </div>
          <span v-if="v$.formParams.updateTime.$invalid && submitted">Enter the field</span>
        </div>
        <button class="button submit" type="submit" @click.prevent="submitForm">Book Now</button>
      </form>
      <div class="totals" v-if="!v$.formParams.updateTravelers.$invalid">
        <div class="sum">
          <div class="title">{{ this.$store.state.currency.symbol +' '+ event[0].price }}{{
                          formParams.updateTravelers.adults >= 2 ? ' x ' +
                          formParams.updateTravelers.adults + ' Adults' :
                          formParams.updateTravelers.adults == 1 ? ' x ' +formParams.updateTravelers.adults + ' Adult' : ''
                        }}</div>
          <div class="result">{{ priceResult() }}</div>
        </div>
        <div class="total">
          <div class="title">In total</div>
          <div class="result">{{ priceResult() }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarPicker from "@/components/MainPages/EventPage/CalendarPicker.vue";
import modalChooseTrevelers from "@/components/MainPages/EventPage/modalChooseTrevelers.vue";
import modalChooseTime from "@/components/MainPages/EventPage/modalChooseTime.vue";
import axios from "axios";
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    CalendarPicker,
    modalChooseTrevelers,
    modalChooseTime
  },
  props: {
    loading: Boolean,
    event: Array,
  },
  data() {
    return {
      formParams: {
        updateData: '',
        updateTime: [],
        updateTravelers: [],
      },
      changeDataEvent: [],
      changeTimeEvent: [],
      dialogs: {
        dialogCalendarPicker: false,
        modalChooseTrevelers: false,
        modalChooseTime: false,
      }
    }
  },
  validations() {
    return {
      formParams: {
        updateData: { required },
        updateTime: { required },
        updateTravelers: { required },
      }
    }
  },
  methods: {
    hideModal(modalName) {
    if (this.dialogs.hasOwnProperty(modalName)) {
      this.dialogs[modalName] = false;
    }
  },
    formatTimeToAMPM(time24) {
      if (!time24) return "Time";
      let [hours, minutes, seconds] = time24.split(':');
      hours = parseInt(hours);
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;
      const formattedTime = minutes === "00" ? `${hours} ${ampm}` : `${hours}:${minutes} ${ampm}`;
      return formattedTime;
    },
    priceResult() {
      const price = this.formParams.updateTravelers.adults * parseFloat(this.event[0].price);
      return this.$store.state.currency.symbol + ' ' + price.toFixed(2);
    },

    submitForm() {
      this.submitted = true;
      this.v$.$touch();
      if(this.$store.state.user.role != null && this.$store.state.user.role == "customer"){
        if (this.v$.formParams.$invalid) {
          console.log('11111');
        } else {
          console.log('222222222');
          this.$router.push({name:"ReservationPage",
          params: {country:this.$route.params.country,city:this.$route.params.city,id:this.$route.params.id},
          query:{ 
            event_id:this.formParams.updateTime.event_id,
            data:this.formParams.updateData,
            id:this.formParams.updateTime.id,
            adults:this.formParams.updateTravelers.adults,
            child:this.formParams.updateTravelers.child
          }
          });
        }
      }else if(this.$store.state.user.role == "business" || this.$store.state.user.role == "admin"){
      }
      else{
        this.$store.state.dialogLoginForm = true;
      }
     
    },
    formatDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1; // добавляем 1, так как месяцы начинаются с 0
      const year = date.getFullYear();

      return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
    }
    return '';
  },
    getEventDateTime() {
      let fData = new FormData();
      fData.append("event_id", this.$route.params.id);
      axios.post("/get/event/date-event", fData).then(resp => {
        this.changeDataEvent = resp.data.data;
        this.dialogs.dialogCalendarPicker = true;
      }).catch(() => {
      }).finally(() => {
      });
    },
    dateConfirm(event) {
      this.formParams.updateData = event;
      let fData = new FormData();
      fData.append("event_id", this.$route.params.id);
      fData.append("date", this.formParams.updateData);
      axios.post("/get/event/time-event", fData).then(resp => {
        this.changeTimeEvent = resp.data.data;
        // this.dialogs.changeTimeCalendar = true;
      }).catch(() => {
      }).finally(() => {
      });
    },
    openCalendar() {
      this.dialogs.dialogCalendarPicker = true;
      document.addEventListener('click', this.handleClickOutside);
    },
    closeCalendar() {
      this.dialogs.dialogCalendarPicker = false;
      document.removeEventListener('click', this.handleClickOutside);
    },
    handleClickOutside(event) {
      const calendarPicker = this.$refs.calendarPicker;
      if (calendarPicker && !calendarPicker.contains(event.target)) {
        this.closeCalendar();
      }
    }

  },
}
</script>

<style scoped>
.info_right {
  width: 34.10%;
}
.form__booking{
  position: sticky;
  top: 120px;
}
.booking_details {
  top: 120px;
  position: sticky;
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 26px 24px;
}

.booking_details .price_person {
  color: #000;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.108px;
  padding-bottom: 16px;
}

.booking_details .price_person span {
  color: #000;
  font-family: Standerd;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.168px;
  padding-right: 12px;
}

.booking_details form {
  padding: 16px 0;
  border-top: 1px solid #E5E5EA;
}

.booking_details form .input__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}
.input__wrapper span{
  color: var(--Red-100, #F72314);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.booking_details form .title {
  color: #000;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  padding-bottom: 16px;
}

.booking_details form .input {
  display: flex;
  position: relative;
  height: 40px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  align-items: center;
}
.booking_details form .input .input-picker {
  color: var(--Black-400, #292929);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.booking_details form .input svg {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.booking_details form .number {
}

.booking_details form .time {
}

.booking_details form .button {
  margin-top: 24px;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: var(--green-0, rgba(0, 211, 72, 0.24));
  color: var(--green-400, #39766A);
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}
.booking_details .totals{
  border-top: 1px solid #E5E5EA;
}
.booking_details .totals .sum,
.booking_details .totals .total {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #E5E5EA;
}

.booking_details .totals .total {
  border-bottom: 0px solid #E5E5EA;
  padding-bottom: 0;
}

.booking_details .totals .sum .title {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  letter-spacing: 0.108px;
}

.booking_details .totals .sum .result {
  color: var(--black-400, #292929);
  text-align: right;
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  letter-spacing: 0.108px;
}

.booking_details .totals .total .title {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: 0.12px;
}

.booking_details .totals .total .result {
  color: var(--black-400, #292929);
  text-align: right;
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  letter-spacing: 0.12px;
}

@media screen and (max-width: 768px) {
  .form__booking{
    position: unset;
    top: unset;
  }
}
</style>