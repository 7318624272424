<template>
    <section class="login">
      <div class="login_content">
        <div class="page_title">Log in to your Amuse account</div>
        <form action="">
          <div class="title">Login</div>
          <div class="inputs__wrapper">
            <div class="input__wrapper">
              <div class="text">Email</div>
              <Input-email :placeholder="'Email'" :email="auth_data.email" @update:email="auth_data.email = $event"></Input-email>
            </div>
            <div class="input__wrapper">
              <div class="text__wrapper">
                <div class="text">Password</div>
                <router-link class="link" :to="{name: 'BusinesEmailVerification'}">Forgot password?</router-link>
              </div>
              <Input-password :placeholder="'Password'" :password="auth_data.password" @update:password="auth_data.password = $event"></Input-password>
              <div class="checkbox">
                <input id="remember_me" class="checkbox_input" type="checkbox" v-model="auth_data.remember_me"/>
                <label for="remember_me"></label>
                <div class="text">remember me</div>
              </div>
            </div>
          </div>
          <button class="btn_login" type="submit" @click="SubmitAuth">Login</button>
        </form>
      </div>
    </section>
    
  </template>
  
  <script>
  import InputPassword from "@/components/UI/InputPassword.vue";
  import InputEmail from "@/components/UI/InputEmail.vue";
  import axios from "axios";
  export default {
    components: {
      InputPassword,
      InputEmail,
    },
    data() {
      return {
        auth_data:{
          email: null,
          password: null,
          remember_me: false,
        },
        request: false,
      }
    },
    methods: {
      async SubmitAuth(e){
        e.preventDefault();
        if(this.request){
          return false;
        }
        this.request = true;
        let current_arr = JSON.parse(JSON.stringify(this.auth_data));
        let fData = new FormData();
  
        fData.append("login",current_arr.email);
        fData.append("password",current_arr.password);
        try {
        const response = await axios.post("/busines/login/account/auth", fData);
        if (response.data['result'] === true) {
          window.localStorage.setItem("auth_token", response.data.token);
          setTimeout(() => {
            this.$router.push({ name: "BusinesDashboard" });
          }, 200);
        } else if (response.data.code && response.data.code === "error_data") {
          this.showMessage(false, "Неверные данные для входа");
        } else {
          this.showMessage(false, "Произошла неизвестная ошибка, попробуйте позже");
        }
        } catch (error) {
          this.showMessage(false, "Произошла неизвестная ошибка, попробуйте позже");
        } finally {
            this.request = false;
        }
      },
      showMessage(type,title){
        if(type) type='success';
        else type="error";
      },
    },
  }
  </script>
  
  <style scoped>
  .checkbox{
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .login {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login_content{
    width: 562px;
    max-width: 562px;
  }
  .login_content .page_title{
    color: var(--black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.192px;
    padding-bottom: 40px;
  }
  .login_content form{
    padding: 16px;
    padding-top: 35px;
    width: 100%;
    border-radius: 8px;
    border: 1.5px solid var(--gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  }
  
  .inputs__wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 20px;
  }
  .login_content .title {
    color: var(--black-300, #333);
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    padding-bottom: 16px;
  }
  .login_content .input__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .text__wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .text__wrapper .link{
    color: var(--black-400, #292929);
    text-align: right;
    font-family: Standerd;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 123.077% */
    text-decoration-line: underline;
  }
  .input__wrapper .text {
    color: var(--black-400, #292929);
    font-family: Standerd;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 123.077% */
  }
  .checkbox .text{
    color: var(--gray-300, #888);
    font-family: Standerd;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 0;
  }
  .input__wrapper input{
    border-radius: 8px;
    border: 1.5px solid var(--gray-stroke, #E5E5EA);
    height: 40px;
    padding-left: 12px;
  }
  
  .login_content .btn_login{
    cursor: pointer;
    border-radius: 8px;
    border: 1.5px solid var(--gray-stroke, #E5E5EA);
    background: var(--white-100, #FFF);
    height: 44px;
    width: 100%;
    color: var(--black-300, #333);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
  }
  
  @media screen and (max-width: 768px) {
    .login_content {
      width: calc(100% - 32px);
    }
    .login_content .page_title{
      color: var(--black-400, #292929);
      text-align: center;
      font-family: Standerd;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.144px;
    }
  }
  </style>