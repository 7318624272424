<template>
  <header-page></header-page>
  <main>
  <section class="companies">
    <div class="companies__container">
      <div class="companies__content">
        <side-bar></side-bar>
        <div class="right__main__content">
          <div class="filter__content">
            <div class="filter_content_wrapper">
            <div class="picker-option">
              <template v-for="status in arr_status">
                <button :class="[status.status_name,{'active': real_filters.status_id == status.id}]"  @click="changeStatus(status.id)" type="button">
                  {{ status.status_name }}
                </button>
                <div class="arrow__top"></div>
              </template>
            </div>
            <ActiveFilters @delete="deleteFilter" :arrFilters="arrFilters" :filters="filters"></ActiveFilters>
            </div>
            <div class="filter">
              <input-filter @value="getSearchData" :placeholder_title="'Search for company'" :data_name="'company_name'" :sort="arr_company" @search="getSearchCompany"></input-filter>
              <filter-button @click="this.dialogs.filterItemDialog =  true"></filter-button>
            </div>
          </div>
          <div>
            <post-list class="active" :posts="arr_data" :loader="data_info.loading">
              <table class="list">
                <thead>
                <tr>
                  <th>Company name</th>
                  <th>Country</th>
                  <th>Event</th>
                  <th>Customer</th>
                  <th>Price</th>
                  <th>Order date</th>
                  <th>Presence</th>
                  <th>Status</th>
                  <th></th>
                </tr>
                </thead>

                <tbody v-if="loadingСompleted">
                <tr v-for="post in this.arr_data"
                    :key="post.id">
                  <td>
                    <div class="company__name">
                      <img v-if="post.company_data.image" :src='this.$store.state.base_directory + "company/"+ post.company_data.id + "/"+ post.company_data.image' alt="">
                      <div class="image_letter" :style="{ background: getBackgroundColor(company_data.company_name) }" v-else>{{ post.company_data.company_name.substr(0, 1) }}</div>
                      <div>{{ post.company_data.company_name }}</div>
                    </div>
                  </td>
                  <td >
                    <div class="table__name">Country name:</div>
                    <div class="text">{{ post.event_data.event_name }}</div>
                  </td>
                  <td class="mobile__none">
                    <div class="table__name">Event name:</div>
                    <div class="text">{{ post.event_data.event_name }}</div>
                  </td>
                  <td>
                    <div class="table__name">Customer:</div>
                    <div class="text">{{ post.customer_name }}</div>
                  </td>
                  <td>
                    <div class="table__name">Price:</div>
                    <div class="text">{{ post.symbol+post.total_price }}</div>
                  </td>
                  <td class="mobile__none">
                    <div class="table__name">Order date:</div>
                    <div class="text">{{ post.order_date }}</div>
                  </td>
                  <td class="mobile__none">
                    <div class="table__name">Presence date:</div>
                    <div class="text">{{ post.presence }}</div>
                  </td>
                  <td>
                    <div class="table__name">Status:</div>
                    <div class="company__status" :class="[ post.color ]">
                      <div>{{ post.status_name }}</div>
                    </div>
                  </td>
                  <td>
                    <div class="company__panelButton">
                        <button @click="confirmDetailsItem(post)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <g clip-path="url(#clip0_3118_41499)">
                              <path d="M16.3333 15.8333H4.66667V4.16667H10.5V2.5H4.66667C3.74167 2.5 3 3.25 3 4.16667V15.8333C3 16.75 3.74167 17.5 4.66667 17.5H16.3333C17.25 17.5 18 16.75 18 15.8333V10H16.3333V15.8333ZM12.1667 2.5V4.16667H15.1583L6.96667 12.3583L8.14167 13.5333L16.3333 5.34167V8.33333H18V2.5H12.1667Z" fill="#292929"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_3118_41499">
                                <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                        <button @click="chatWithUser(post.customer)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M12.4993 3.33329V9.16663H4.30768L3.33268 10.1416V3.33329H12.4993ZM13.3327 1.66663H2.49935C2.04102 1.66663 1.66602 2.04163 1.66602 2.49996V14.1666L4.99935 10.8333H13.3327C13.791 10.8333 14.166 10.4583 14.166 9.99996V2.49996C14.166 2.04163 13.791 1.66663 13.3327 1.66663ZM17.4993 4.99996H15.8327V12.5H4.99935V14.1666C4.99935 14.625 5.37435 15 5.83268 15H14.9993L18.3327 18.3333V5.83329C18.3327 5.37496 17.9577 4.99996 17.4993 4.99996Z" fill="#292929"/>
                          </svg>
                        </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </post-list>
            <list-preloader v-if="!loadingСompleted"></list-preloader>
            <list-flip @update="onPage" v-if="data_info.loading || arr_data.length > 0" :class="{ active: loadingСompleted }" :page="data_info.page" :currenNumber="arr_data.length" :allCount="data_info.allCount" :next="data_info.next"></list-flip>
          </div>
        </div>
        <dialog-details class="order_admin_dialog" v-model:show="dialogs.detailsItemDialog" :modal="true">
          <Button class="exit-dialog" @click="this.dialogs.detailsItemDialog = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
            </svg>
          </Button>
          <div class="content">
            <div class="title_modal">Order details</div>
            <div class="top_nav_bnt">
              <div class="company_detail">
                <img v-if="details_data.company_data.image" :src='this.$store.state.base_directory + "company/"+ details_data.company_data.id + "/"+ details_data.company_data.image' alt="">
                <div class="image_letter" :style="{ background: getBackgroundColor(details_data.company_data.company_name) }" v-else>{{ details_data.company_data.company_name.substr(0, 1) }}</div>
                  <div class="company__name">{{ details_data.company_data.company_name }}</div>
              </div>
            </div>
            <div class="all_details_info">
              <div class="item">
                <div class="left">Company:</div>
                <div class="right">
                  <div class="text">{{ details_data.company_data.company_name }}</div>
                  <div class="company__panelButton">
                    <button @click="chatWithOrganizator(details_data.company_data.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.4993 3.33334V9.16667H4.30768L3.33268 10.1417V3.33334H12.4993ZM13.3327 1.66667H2.49935C2.04102 1.66667 1.66602 2.04167 1.66602 2.50001V14.1667L4.99935 10.8333H13.3327C13.791 10.8333 14.166 10.4583 14.166 10V2.50001C14.166 2.04167 13.791 1.66667 13.3327 1.66667ZM17.4993 5.00001H15.8327V12.5H4.99935V14.1667C4.99935 14.625 5.37435 15 5.83268 15H14.9993L18.3327 18.3333V5.83334C18.3327 5.37501 17.9577 5.00001 17.4993 5.00001Z" fill="#292929"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">Date & time:</div>
                <div class="right">
                  <div class="text">{{ details_data.day_execution }}</div>
                </div>
              </div>
              <div class="item">
                <div class="left">Main customer:</div>
                <div class="right">
                  <div class="text">{{ details_data.customer_name }}</div>
                  <div class="company__panelButton">
                    <button @click="chatWithUser(details_data.customer)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.4993 3.33334V9.16667H4.30768L3.33268 10.1417V3.33334H12.4993ZM13.3327 1.66667H2.49935C2.04102 1.66667 1.66602 2.04167 1.66602 2.50001V14.1667L4.99935 10.8333H13.3327C13.791 10.8333 14.166 10.4583 14.166 10V2.50001C14.166 2.04167 13.791 1.66667 13.3327 1.66667ZM17.4993 5.00001H15.8327V12.5H4.99935V14.1667C4.99935 14.625 5.37435 15 5.83268 15H14.9993L18.3327 18.3333V5.83334C18.3327 5.37501 17.9577 5.00001 17.4993 5.00001Z" fill="#292929"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="item column" v-if="details_data.additional_customers && details_data.additional_customers.length > 0">
                <div class="left">Adult:</div>
                <div class="right column">
                  <div v-for="item in details_data.additional_customers" class="text">
                    {{item}}
                  </div>
                </div>
              </div>
              <div class="item column" v-if="details_data.additional_child && details_data.additional_child.length > 0">
                <div class="left">Child:</div>
                <div class="right column">
                  <div v-for="item in details_data.additional_child" class="text">
                    {{item}}
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">Status:</div>
                <div class="right">
                  <div class="text" :class="[ details_data.color ]">{{ details_data.status_name }}</div>
                </div>
              </div>
              <div class="item">
                <div class="left">Total price:</div>
                <div class="right">
                  <div class="text">{{ details_data.symbol+' '+details_data.total_price }}</div>
                </div>
              </div>
              <div class="item">
                <div class="left">Order date:</div>
                <div class="right">
                  <div class="text">{{ details_data.order_date }}</div>
                </div>
              </div>
              <div class="item">
                <div class="left">Presense:</div>
                <div class="right">
                  <div class="text">{{ details_data.presence }}</div>
                </div>
              </div>
            </div>
          </div>
        </dialog-details>
        <dialog-filter v-model:show="dialogs.filterItemDialog" :modal="true">
          <div>
            <div class="title__filter">Filter</div>
            <div class="filters">
              <div class="selected">
                <div class="title">Order date</div>
                <InputPickerFilter @show="dialogs.calendarDialog = true; dialogs.filterItemDialog = false"  :placeholder_title="'Sort by date'"></InputPickerFilter>
              </div>
              <div class="selected">
                <div class="title">Event</div>
                <Select v-model="filters['event_id']" :placeholder_title="'Sort by event'" :data_name="'event_name'" :sort="event_filters" @search="getSearchEvent"></Select>
              </div>
              <div class="selected">
                <div class="title">Country</div>
                <Select v-model="filters['country_id']" :placeholder_title="'Sort by country'" :data_name="'name'" :sort="arr_country_filters" @search="getSearchCountry"></Select>
              </div>
              <div class="rate">
                <div class="title">Rate</div>
                <template v-for="rate in rates">
                  <div class="checkbox">
                    <input :id="rate.count" class="checkbox_input" :value="rate" type="checkbox" v-model="selectedRate" />
                    <label :for="rate.count"></label>
                    <div class="count_stars">
                      <svg v-for="n in rate.count" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M11.6586 3.73839C11.6886 3.67335 11.7367 3.61825 11.797 3.57964C11.8574 3.54103 11.9275 3.52051 11.9991 3.52051C12.0708 3.52051 12.1409 3.54103 12.2013 3.57964C12.2616 3.61825 12.3096 3.67335 12.3396 3.73839L14.6624 8.77389C14.6892 8.8321 14.7306 8.88245 14.7824 8.92014C14.8343 8.95782 14.895 8.98158 14.9586 8.98914L20.4644 9.64164C20.5356 9.65011 20.6028 9.67879 20.6582 9.7243C20.7136 9.7698 20.7547 9.83023 20.7768 9.89841C20.7989 9.9666 20.801 10.0397 20.7829 10.109C20.7647 10.1784 20.7271 10.241 20.6744 10.2896L16.6041 14.0546C16.5572 14.0982 16.5223 14.1531 16.5026 14.214C16.4829 14.2749 16.4791 14.3399 16.4916 14.4026L17.5716 19.8409C17.5857 19.9112 17.5793 19.9841 17.5532 20.0509C17.5271 20.1177 17.4823 20.1756 17.4243 20.2178C17.3663 20.2599 17.2974 20.2845 17.2258 20.2887C17.1542 20.2929 17.0829 20.2765 17.0204 20.2414L12.1829 17.5331C12.1268 17.5016 12.0635 17.485 11.9991 17.485C11.9348 17.485 11.8715 17.5016 11.8154 17.5331L6.97788 20.2406C6.9154 20.2757 6.84419 20.2921 6.77269 20.2879C6.70118 20.2837 6.63237 20.2592 6.57438 20.2171C6.5164 20.1751 6.47167 20.1173 6.44548 20.0506C6.4193 19.9839 6.41276 19.9112 6.42663 19.8409L7.50663 14.4026C7.51928 14.3397 7.51558 14.2747 7.49588 14.2136C7.47618 14.1525 7.44115 14.0975 7.39413 14.0539L3.32313 10.2889C3.27068 10.2403 3.23324 10.1776 3.21522 10.1084C3.1972 10.0392 3.19935 9.96624 3.22143 9.8982C3.24351 9.83017 3.28458 9.76986 3.33981 9.7244C3.39504 9.67894 3.46212 9.65023 3.53313 9.64164L9.03963 8.98914C9.10342 8.9817 9.16424 8.958 9.21624 8.92031C9.26825 8.88262 9.3097 8.8322 9.33663 8.77389L11.6586 3.73839Z" fill="#292929" stroke="#292929" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <svg v-for="n in 5-rate.count" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M11.6586 3.73839C11.6886 3.67335 11.7367 3.61825 11.797 3.57964C11.8574 3.54103 11.9275 3.52051 11.9991 3.52051C12.0708 3.52051 12.1409 3.54103 12.2013 3.57964C12.2616 3.61825 12.3096 3.67335 12.3396 3.73839L14.6624 8.77389C14.6892 8.8321 14.7306 8.88245 14.7824 8.92014C14.8343 8.95782 14.895 8.98158 14.9586 8.98914L20.4644 9.64164C20.5356 9.65011 20.6028 9.67879 20.6582 9.7243C20.7136 9.7698 20.7547 9.83023 20.7768 9.89841C20.7989 9.9666 20.801 10.0397 20.7829 10.109C20.7647 10.1784 20.7271 10.241 20.6744 10.2896L16.6041 14.0546C16.5572 14.0982 16.5223 14.1531 16.5026 14.214C16.4829 14.2749 16.4791 14.3399 16.4916 14.4026L17.5716 19.8409C17.5857 19.9112 17.5793 19.9841 17.5532 20.0509C17.5271 20.1177 17.4823 20.1756 17.4243 20.2178C17.3663 20.2599 17.2974 20.2845 17.2258 20.2887C17.1542 20.2929 17.0829 20.2765 17.0204 20.2414L12.1829 17.5331C12.1268 17.5016 12.0635 17.485 11.9991 17.485C11.9348 17.485 11.8715 17.5016 11.8154 17.5331L6.97788 20.2406C6.9154 20.2757 6.84419 20.2921 6.77269 20.2879C6.70118 20.2837 6.63237 20.2592 6.57438 20.2171C6.5164 20.1751 6.47167 20.1173 6.44548 20.0506C6.4193 19.9839 6.41276 19.9112 6.42663 19.8409L7.50663 14.4026C7.51928 14.3397 7.51558 14.2747 7.49588 14.2136C7.47618 14.1525 7.44115 14.0975 7.39413 14.0539L3.32313 10.2889C3.27068 10.2403 3.23324 10.1776 3.21522 10.1084C3.1972 10.0392 3.19935 9.96624 3.22143 9.8982C3.24351 9.83017 3.28458 9.76986 3.33981 9.7244C3.39504 9.67894 3.46212 9.65023 3.53313 9.64164L9.03963 8.98914C9.10342 8.9817 9.16424 8.958 9.21624 8.92031C9.26825 8.88262 9.3097 8.8322 9.33663 8.77389L11.6586 3.73839Z" stroke="#292929" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="btn">
            <Button class="white" @click="clearAll">Clear All</Button>
            <Button class="green" @click="onFilter">Show options</Button>
          </div>
          <Button class="exit-dialog" @click="this.dialogs.filterItemDialog = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z" fill="#292929"/>
            </svg>
          </Button>
        </dialog-filter>
        <picker-dialog v-model:show="dialogs.calendarDialog" :modal="true" @add="filterDate" ></picker-dialog>
      </div>
    </div>
  </section>
  </main>
</template>

<script>
import ListFlip from "@/components/Admin/ListFlip.vue";
import PostList from "@/components/Admin/AdminPanelList.vue";
import axios from "axios";
import ListPreloader from "@/components/UI/Admin/ListPreloader.vue";

export default {
  components: {ListPreloader, PostList, ListFlip},
  data() {
    return {
      loadingСompleted: false,
      rates:[
        {name:'rate', count:5},
        {name:'rate', count:4},
        {name:'rate', count:3},
        {name:'rate', count:2},
        {name:'rate', count:1}
      ],
      selectedRate:[],
      selectedCategory:[],
      arr_data: [],
      arr_status:[],
      arr_category: [],
      details_data:
        {"id":1,
          "company_id":8,
          "event_id":1,
          "customer":"Dima",
          "additional_customers":"Nikita,Vova",
          "total_price":145,
          "date_and_time":"01 Sep 2023 11:43 PM",
          "order_date":"01 Sep 2023",
          "rate":5,
          "presence":"Yes",
          "status_id":4,
          "created_at":null,
          "updated_at":null,
          "company_data":{
            "id":8,
            "image":"",
            "company_name":"Anex Tour Agency"
          },
          "event_data":{
            "id":1,
            "event_name":"See bali in bali",
            "price":70
          },
          "status_name":"Approved",
          "color":"green",
          "additional_customers_data":["Nikita","Vova"]}
      ,
      arrFilters:{
        'order_date' : 'Date',
        'event_id': 'Event',
        'country_id': 'Country',
        'company_id' : 'Company',
        'rate' : 'Rate'
      },
      selectedStatus: 4,
      filters: {
        'status_id': 4,
      },
      real_filters:{

      },
      arr_country_filters:[],
      event_filters:[],
      arr_company:[],
      data_info:{
        loading: true,
        totalRecords: 0,
        page:0,
        next: true,
        allCount: null,
        sort:{
          field:"",
          order:1,
        },
        selectedItems: [],
        selectAll: false,
      },
      dialogs:{
        filterItemDialog: false,
        detailsItemDialog: false,
        calendarDialog: false,
      },
    }
  },
  methods: {
    getBackgroundColor(name) {
      // Простейшая хэш-функция для генерации цвета на основе имени
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    },
    chatWithOrganizator(to_id){
        let fData = new FormData();
        fData.append("to_id", to_id);
        axios.post("/admin/companies/chat-organizator", fData).then(resp => {
            this.$router.push({
                name: 'AdminMessage',
                query: { chat_id: resp.data.data }
            });
        }).catch(() => {
        }).finally(() => {
        });
    },
    chatWithUser(to_id){
        let fData = new FormData();
        fData.append("to_id", to_id);
        axios.post("/admin/companies/chat-user", fData).then(resp => {
            this.$router.push({
                name: 'AdminMessage',
                query: { chat_id: resp.data.data,
                    tab: 2
                 }
            });
        }).catch(() => {
        }).finally(() => {
        });
    },
    getData(){
      this.loadingСompleted = false;
      this.filters.status_id = this.selectedStatus;
      this.real_filters = this.filters;
      let fData = new FormData();
      if(this.arr_data.length<=0){
        this.data_info.page--;
        if(this.data_info.page<0) this.data_info.page = 0;
      }
      fData.append("page",this.data_info.page);
      fData.append("filters",JSON.stringify(this.real_filters));
      axios.post("/admin/orders",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.arr_data = resp.data.data;
          this.data_info.allCount = resp.data.allCount;
          this.data_info.next = resp.data.next;
          this.data_info.loading = false;
        }
      }).catch(()=>{}).finally(()=>{
        this.loadingСompleted = true;
      });
    },
    getInit(){
      axios.get("/admin/orders-init")
          .then((response) => {
            this.arr_status = response.data.status;
          })
    },
    changeStatus(e){
      this.selectedStatus = e;
      this.data_info.loading = true;
      this.filters.status_id = this.selectedStatus;
      this.getData();
    },
    getSearchEvent(e){
      let fData = new FormData();
      fData.append("name",e);
      axios.post("/admin/orders-event-filter",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.event_filters = resp.data.country;
        }
      }).catch(()=>{}).finally(()=>{});
    },
    onPage(e){
      this.data_info.loading = true;
      this.data_info.page = e;
      this.getData();
    },
    getSearchCountry(e){
      let fData = new FormData();
      fData.append("name",e);
      axios.post("/admin/orders-country-filter",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.arr_country_filters = resp.data.country;
        }
      }).catch(()=>{}).finally(()=>{});
    },
    getSearchCompany(e){
      let fData = new FormData();
      fData.append("name",e);
      axios.post("/admin/orders-company-filter",fData).then(resp => {
        if (resp.data['result'] == true) {
          this.arr_company = resp.data.company;
        }
      }).catch(()=>{}).finally(()=>{});
    },
    getSearchData(e){
      if (e == '') delete this.filters['company_id'];
      else this.filters['company_id'] = e;
      this.getData();
    },
    onFilter(){
      if(this.selectedRate.length > 0){
      this.filters['rate'] = JSON.parse(JSON.stringify(this.selectedRate))
      };
      this.real_filters = JSON.parse(JSON.stringify(this.filters));
      this.data_info.page=0;
      this.dialogs.filterItemDialog = false;
      this.getData();
    },
    filterDate(value){
      this.filters['order_date'] = JSON.parse(JSON.stringify(value));
      this.dialogs.calendarDialog = false;
      this.getData();
    },
    clearAll(){
      this.dialogs.filterItemDialog = false;
      this.filters = {};
      this.getData();
    },
    deleteFilter(item){
      delete this.filters[item];
      this.getData();
    },
    confirmDetailsItem(data){
        this.details_data = data;
        this.dialogs.detailsItemDialog = true;
    },
  },
  mounted() {
    this.getInit();
    this.getData();
    this.getSearchCompany('');
  }
}
</script>

<style scoped>
.companies__content {
  display: flex;
  gap: 24px;
}
.item .text.green{
  color: var(--Green-400, #39766A);
font-family: Standerd;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 25.5px */
letter-spacing: -0.374px;
}
.item .text.orange{
  color: var(--Yellow-400, #D38004);
font-family: Standerd;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 25.5px */
letter-spacing: -0.374px;
}
.item .text.red{
  color: var(--Red-200, #EA2313);
font-family: Standerd;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 25.5px */
letter-spacing: -0.374px;
}
.content .all_details_info{
  height: unset;
}
.order_admin_dialog .content{
  max-height: calc(100% - 142px);
  height: unset;
}
.order_admin_dialog .all_details_info{

}
@media screen and (max-width: 1024px) {
    .order_admin_dialog .all_details_info {
        overflow-y: overlay;
        max-height: 100%;
    }
}
</style>