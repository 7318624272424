<template>
  <div class="otherUser" ref="messageList" @scroll="handleScroll">
    <div v-if="loading">Loading...</div>
    <div v-for="message in messages" :key="message.id" >
      <div class="messages current-user" :class="{unread: message.is_read === null && message.from_id !== this.$store.state.user.id}" v-if="message.from_id == user.id" :key="message.id" :ref="'message_' + message.id">
        <div class="massage__wrapper">
          <div class="massage" v-if="message.type_content == 'text'" >{{ message.content}}</div>
          <a v-else href="#" @click.prevent="saveImage(getFileUrl(message), message.content)">
              <img :src="getIcon(message.type_content)" alt="File Icon" class="file-icon">
              <img :src="require(`@/assets/image/chats-messages/file_download.svg`)" alt="File Icon" class="file-icon download">
              <div class="file-info">
                  <div>{{ message.content }}</div>
                  <span>{{ formatFileSize(message.file_size) }}</span>
              </div>
          </a>
          <div class="current__state">
            <div class="user">Me</div>
            <div class="circle"></div>
            <div class="time">{{ message.created_at}}</div>
            <svg v-if="message.is_read === 1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_4255_39517)">
                <path d="M10.4991 4.08333L9.67661 3.26083L5.97828 6.95917L6.80078 7.78167L10.4991 4.08333ZM12.9724 3.26083L6.80078 9.4325L4.36245 7L3.53995 7.8225L6.80078 11.0833L13.8008 4.08333L12.9724 3.26083ZM0.238281 7.8225L3.49911 11.0833L4.32161 10.2608L1.06661 7L0.238281 7.8225Z" fill="#606060"/>
              </g>
              <defs>
                <clipPath id="clip0_4255_39517">
                  <rect width="14" height="14" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <svg v-if="message.is_read === null" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_4255_15856)">
                <path d="M5.25104 9.44978L2.80104 6.99978L1.98438 7.81645L5.25104 11.0831L12.251 4.08312L11.4344 3.26645L5.25104 9.44978Z" fill="#606060"/>
              </g>
              <defs>
                <clipPath id="clip0_4255_15856">
                  <rect width="14" height="14" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <div class="messages other-user" :class="{unread: message.is_read === null && message.from_id !== this.$store.state.user.id}" v-else :key="message.id" :ref="'message_' + message.id">
        <img class="image" v-if="otherUser.user.image && otherUser.user.type == 1" :src='this.$store.state.base_directory + "admins/"+ otherUser.user.id + "/"+ otherUser.user.image' alt="">
        <img class="image" v-else-if="otherUser.user.image && otherUser.user.type == 2" :src='this.$store.state.base_directory + "company/"+ otherUser.user.id + "/"+ otherUser.user.image' alt="">
        <img class="image" v-else-if="otherUser.user.image && otherUser.user.type == 3" :src='this.$store.state.base_directory + "users/"+ otherUser.user.id + "/"+ otherUser.user.image' alt="">
        <div v-else class="image_letter" :style="{ background: getBackgroundColor(otherUser.user.name) }" >{{ otherUser.user.name.substr(0, 1) }}</div>
        <div class="massage__wrapper">
          <div class="massage" v-if="message.type_content == 'text'">{{ message.content}}</div>
          <a v-else href="#" @click.prevent="saveImage(getFileUrl(message), message.content)">
              <img :src="getIcon(message.type_content)" alt="File Icon" class="file-icon">
              <img :src="require(`@/assets/image/chats-messages/file_download.svg`)" alt="File Icon" class="file-icon download">
              <div class="file-info">
                  <div>{{ message.content }}</div>
                  <span>{{ formatFileSize(message.file_size) }}</span>
              </div>
          </a>
          <div class="current__state">
            <div v-if="otherUser.user.type == 1" class="user">{{ otherUser.user.name }}</div>
            <div v-else-if="otherUser.user.type == 2" class="user">{{ otherUser.user.company_name }}</div>
            <div v-else-if="otherUser.user.type == 3" class="user">{{ otherUser.user.name }} {{ otherUser.user.last_name }}</div>
            <div class="circle"></div>
            <div class="time">{{ message.created_at}}</div>
            <svg v-if="message.is_read === 1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_4255_39517)">
                <path d="M10.4991 4.08333L9.67661 3.26083L5.97828 6.95917L6.80078 7.78167L10.4991 4.08333ZM12.9724 3.26083L6.80078 9.4325L4.36245 7L3.53995 7.8225L6.80078 11.0833L13.8008 4.08333L12.9724 3.26083ZM0.238281 7.8225L3.49911 11.0833L4.32161 10.2608L1.06661 7L0.238281 7.8225Z" fill="#606060"/>
              </g>
              <defs>
                <clipPath id="clip0_4255_39517">
                  <rect width="14" height="14" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <svg v-if="message.is_read === null" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_4255_15856)">
                <path d="M5.25104 9.44978L2.80104 6.99978L1.98438 7.81645L5.25104 11.0831L12.251 4.08312L11.4344 3.26645L5.25104 9.44978Z" fill="#606060"/>
              </g>
              <defs>
                <clipPath id="clip0_4255_15856">
                  <rect width="14" height="14" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import fileSaver from 'file-saver'
export default {
  components: {
    fileSaver,
  },
 name: 'massages',
  props: {
    messages: Object,
    otherUser: Object,
    next: Boolean,
    loading: Boolean,
    messageSend: Boolean,

  },
  data() {
    return {
      processedIds: [],
      use: false,
      scrollTop: 0,
      more: false,
    }
  },
  methods: {
    getBackgroundColor(name) {
      // Простейшая хэш-функция для генерации цвета на основе имени
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    },
    saveImage(url, name) {
      fileSaver.saveAs(url, name)
    },
    getIcon(type) {
      switch (type) {
        case 'image':
          return require('@/assets/image/chats-messages/photofile.svg');
        case 'video':
          return require('@/assets/image/chats-messages/videofile.svg');
        default:
          return require('@/assets/image/chats-messages/attach.svg');
      }
    },
    getFileUrl(message) {
      return `${this.$store.state.base_directory}chats/${message.chat_id}/${message.content}`;
    },
    formatFileSize(size) {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(1) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    },
    async handleScroll() {
      if(this.next === true){
      const messageList = this.$refs.messageList;
        console.log(messageList.scrollTop);
        if (messageList.scrollTop === 0) {
            await this.$emit('loadMore');
            this.more = true;
        }
      }
    },
    markAsRead(messageId) {
      this.$emit('messageRead',messageId);
    },
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    scrollChat(){
      const container = this.$refs.messageList;
      const unreadMessages1 = container.querySelectorAll('.unread');
      if (unreadMessages1.length > 0) {
        console.log(1);
        this.scrollToUnreadMessages();
      }
      else if(unreadMessages1.length === 0 && this.use === false){
        console.log(2);
        this.use = true;
        this.scrollToBottom();
      }
      else if(this.messageSend === true){
        console.log(3);
        this.scrollToBottom();
      }
      else if(this.more === true){
        console.log(4);
        this.scrollToCurrent();
      }
    },
    scrollToUnreadMessages() {
      const unreadMessages = this.messages.filter(message => message.is_read === null && message.from_id !== this.$store.state.user.id);
      
      unreadMessages.forEach(message => {
        const messageElement = this.$refs['message_' + message.id][0];
        if (this.isElementInViewport(messageElement) && !this.processedIds.includes(message.id)) {
          console.log('unreadMessages',unreadMessages);
          this.markAsRead(message.id);
          this.processedIds.push(message.id);
        }
      });
      const container = this.$refs.messageList;
      const unreadMessages1 = container.querySelectorAll('.unread');
      const lastUnreadMessage = unreadMessages1[unreadMessages1.length - 1];
      lastUnreadMessage.scrollIntoView({behavior: 'smooth'});
    },
    scrollToBottom() {
      const messageList = this.$refs.messageList;
      messageList.scrollTop = messageList.scrollHeight;
    },
    scrollToCurrent(){
      this.$refs.messageList.scrollTop = this.$refs.messageList.scrollHeight - this.scrollTop - 40;
      this.more = false;
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  updated() {
      this.scrollChat();
  },
  beforeUpdate() {
    this.scrollTop = this.$refs.messageList.scrollHeight;
  },
}
</script>

<style scoped>
.otherUser {
  width: 100%; /* Задайте ширину чата по вашему усмотрению */
  margin: 0 auto;
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  flex-grow: 1;
  max-height: 100%;
}
.messages {
  width: 100%;
  display: flex;
}
.messages .image{
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.messages .image_letter{
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #FFF;
  text-align: center;
  font-family: Standerd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.massage__wrapper {
   max-width: 70%;
}
.massage__wrapper img{
  width: 24px;
  height: 24px;
}
.massage__wrapper video{
max-width: 300px;
  max-height: 300px;
}
.massage__wrapper a{
  display: flex;
  align-items: center;
  gap: 10px;
  height: 52px;
  padding: 0 14px;
  border-radius: 12px 12px 1px 12px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
  min-width: 216px;
}
.messages.other-user{
  display: flex;
  align-items: end;
  gap: 12px;
}
.messages.current-user{
  display: flex;
  align-items: end;
  gap: 12px;
  justify-content: end;
}
.messages.other-user .image{
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.other-user {
  float: left;
  clear: both;
}
.current-user {
  float: right;
  clear: both;
}
.file-info{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.file-info div{
  color: var(--Black-300, #333);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;
}
.file-info span{
  color: var(--Gray-300, #888);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.06px;
}
.massage__wrapper{
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.other-user .massage{
  border-radius: 12px 12px 12px 1px;
}
.current-user .massage{
  border-radius: 12px 12px 1px 12px;
}
.other-user .massage__wrapper{
  align-items: start;
}
.current-user .massage__wrapper{
  align-items: end;
}
.massage__wrapper .massage{
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02);
  padding: 14px;

  color: var(--black-300, #333);
  leading-trim: both;
  text-edge: cap;
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.084px;

  
}
.massage__wrapper .file-icon.download{
  display: none;
}
.massage__wrapper:hover .file-icon{
  display: none;
}
.massage__wrapper:hover .file-icon.download{
  display: block;
}
.current__state{
  display: flex;
  align-items: center;
  gap: 6px;
}
.current__state .user{
  color: var(--gray-400, #606060);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.072px;
}
.current__state .circle{
  width: 4px;
  height: 4px;
  background: #888;
  border-radius: 50%;
}
.current__state .time{
  color: var(--gray-400, #606060);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.072px;
}
@media screen and (max-width: 1024px) {
  .otherUser {
    background: #F5F5F5;
  }
}
</style>